<template>
  <div>

    <div class="section padding-top-bottom-small background-black over-hide footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3 text-center text-md-left">
            <img src="assets/img/logo (2).png" alt="">
            <p class="color-grey mt-4">Simply because your <br> holiday means the world to us...</p>
          </div>
          <div class="col-md-4 text-center text-md-left">
            <h6 class="color-white mb-3">information</h6>
            <a href="/terms">terms &amp; conditions</a>
            <a href="/services">services</a>
            <a href="/about">about us</a>
            <a href="/contact">contact us</a>
          </div>
          <div class="col-md-5 mt-4 mt-md-0 text-center text-md-left logos-footer">
            <h6 class="color-white mb-3">about us</h6>
            <p class="color-grey mb-4">Have My Room is a responsive application strategically designed to offer innovative and accessible solutions to travelers across the world.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="section py-4 background-dark over-hide footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-center text-md-left mb-2 mb-md-0">
            <p>2021 © Havemyroom. All rights reserved.</p>
          </div>
          <div class="col-md-6 text-center text-md-right">
            <a href="https://www.facebook.com/havemyroom" class="social-footer-bottom">facebook</a>
            <a href="https://www.twitter.com/havemyroom" class="social-footer-bottom">twitter</a>
            <a href="https://www.instagram.com/havemyroom" class="social-footer-bottom">instagram</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {}
}
</script>

import * as geofirex from 'geofirex';
import firebase from './firebase'
import {get} from "geofirex";
export const geo = geofirex.init(firebase);


export async function fetchRoomsInFirestore (latitude, longitude, firestore_collection) {

  let lat = parseFloat(latitude)
  let lon = parseFloat(longitude)
  const center = geo.point(lat, lon);
  const radius = 5;
  const field = 'geoLocation';
  const queryFirestore = firestore_collection.where('isVisible', '==', true)

  //query the rooms
  const geoRef = geo.query(queryFirestore)
  const geoQuery = geoRef.within(center, radius, field)
  const list_of_nearby_hotels = await get(geoQuery)
  return list_of_nearby_hotels
}

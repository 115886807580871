<template>
    <div>
        <Navbar/>
        <div class="container-fluid px-0">
            <div class="section big-55-height over-hide z-bigger" style="height:250px">
                <div class="parallax parallax-top" style="background-image: url('../../assets/img/image6.jpeg')"></div>
                <div class="dark-over-pages"></div>

                <div class="hero-center-section pages">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12 parallax-fade-top">
                                <div class="hero-text">Profile</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row py-5 px-4py-5 px-4">
                <div class="col-xl-8 col-md-8 col-sm-12 mx-auto">

                    <!-- Profile widget -->
                    <div class="bg-white shadow rounded overflow-hidden" ref="update">
                        <div class="px-4 pt-0 pb-4" style="background-image: url('assets/img/image10.jpg')">
                            <div class="media align-items-end profile-header">
                                <div class="profile mr-3"><img v-if="user.data.pictureURL"
                                                               :src="user.data.pictureURL"  width="130"
                                                               class="rounded mb-2 img-thumbnail">
                                    <img v-else
                                         src="assets/img/profile.jpg"  width="130"
                                         class="rounded mb-2 img-thumbnail">
                                </div>
                                <div class="media-body mb-5 text-white">
                                    <h4 class="mt-0 mb-0" style="text-transform: capitalize;">{{ user.data.fullName }}</h4>
                                    <h5 class="mt-2" style="font-size: 12px;">Joined Date</h5>
                                    <p class="small mb-4" v-if="user.data.createdAt">
                                        {{ get_readable_date(user.data.createdAt) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="py-6 px-4">
                        <div class="bg-light">
                            <div class="row mobi pt-5">
                                <div class="col-lg-3 col-md-3 col-sm-3"><!--<i class="fa fa-envelope fa-2x" aria-hidden="true"></i> -->
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1"><i class="fa fa-envelope mobi-icon" aria-hidden="true"></i>
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-8"><h5>Email</h5>
                                    <p>{{ user.data.email }}</p></div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3 col-md-3 col-sm-3"> <!--<i class="fas fa-phone fa-2x" aria-hidden="true"></i> -->
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1"> <i class="fas fa-phone mobi-icon" aria-hidden="true"></i>
                                </div>
                                <div class="col-lg-8 col-md-8 col-sm-8"><h5>Phone</h5>
                                    <p>{{ user.data.phone }}</p></div>
                            </div>
                        </div>


                        <div class="py-1 mt-5">
                          <form @submit.prevent="updateProfile">
                            <div class="form-group">
                              <label for="fullName">Full Name</label>
                              <input type="text" class="form-control" id="fullName" v-model="user.data.fullName"  placeholder="FullName">
                            </div>
                            <div class="form-group">
                              <label for="phone">Phone Number</label>
                              <input type="tel" class="form-control" id="phone" v-model="user.data.phone" placeholder="Phone Number">
                            </div>
                            <button type="submit" class="btn btn-gold btn-block">Update Profile</button>
                          </form>
                        </div>

                    </div>
                    <!-- End profile widget -->


                </div>
            </div>
            <div>

            </div>
        </div>

        <Footer/>
    </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters, mapActions} from "vuex";
import methodMixins, {Toast} from "../../utils/mixins/methodMixins";
// import $ from 'jquery'

export default {
    name: 'Profile',
    methods: {
        async updateProfile() {
            const loader = this.$loading.show({container: this.$refs.update})
            const response = await this.$store.dispatch('user/updateUser', this.user)
            if (response.status) {
                loader.hide()
                await Toast.fire({
                    icon: 'success',
                    title: `${response.message}`
                })
                // await  $("#faithModal").modal("hide");
            } else {
                loader.hide()
                await Toast.fire({
                    icon: 'error',
                    title: `${response.message}`
                })
            }
        },
        get_readable_date: (timeStamp) => methodMixins.methods.get_shorthand_dates(timeStamp),

        ...mapActions('auth', ['logout'])
    },
    components: {
        Navbar,
        Footer
    },
    computed: {
        ...mapGetters({
            user: 'user/get_user'
        })
    }
}
</script>

<style scoped>
a{
    color: #444;
}
.button {
    background-color: goldenrod;
    color: white;
    border-radius: 7%;
    padding: 10px 80px 10px 80px;
}


input {
    border-radius: 10px;
    opacity: 0.5;
    background-color: white;
}

input:hover {
    background-color: white;
}

.form {
    border: none;
    width: 80%;
}

.btn-block {
    background-color: goldenrod;
    color: white;
    border-radius: 10px;
}

.btn-block:hover {
    background-color: gray;
    border: none;
}
h5{
    font-size: 16px;
}
.profile-header {
    transform: translateY(5rem);
}

@media screen and (max-width: 375px) {
    .mobi {
        padding-top: 60px;
    }
}
@media screen and (max-width: 800px) {
    .mobi-icon {
        display: none;
    }
}

</style>

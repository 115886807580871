<template>
  <div>
    <Navbar/>
    <div class="section">
      <div class="page-content" style="background-image: url('assets/img/image5.jpeg')">
    <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6  col-md-9 align-self-center">
              <!-- Display a payment form -->
              <form @submit.prevent="createPaymentIntent" class="mb-5" style="background-color:white" ref="paymentForm">
                <h5 class="text-center">CheckOut</h5>
                <p class="text-center">{{ room.name }}</p>
                <div class="row justify-content-center">
                  <img class="img-responsive cc-img" style="width: 250px; "
                       src="http://www.prepbootstrap.com/Content/images/shared/misc/creditcardicons.png">
                </div>
                <div class="form-group mt-3 text-left">
                  <input type="text" v-model="first_name" placeholder="First name"/>
                </div>
                <div class="form-group mt-3 text-left">
                  <input type="text" v-model="last_name" placeholder="Last name"/>
                </div>

                <div class="form-group text-left">
                  <input type="tel" placeholder="Phone number" v-model="contact_no"/>
                </div>
                <div class="form-group  text-left">
                  <country-select :autocomplete="true" className="form-control" v-model="nationality"
                                  :country="nationality" topCountry="US" style="height: 50px;font-size: 16px"/>
                  <!--                                    <region-select v-model="region" :country="nationality" :region="region" />-->

                </div>
                <template v-if="isHotDeal">
                  <div class="form-group mb-5 text-left">
                    <HotelDatePicker class="myDate"
                                     :startDate="date_option.availabilityStart"
                                     :endDate="date_option.availabilityEnd"
                                     :displayClearButton=false
                                     @period-selected="setDate"
                                     :i18n="i18n"
                    />
                  </div>
                </template>

                <div class="mb-5">
                  <div class="card">
                    <div class="card-body px-1" style="border-bottom: 1px solid rgba(0,0,0,.1)">
                      <div class="col-12 card-title text-left">
                        <strong>Cost Summary</strong>
                      </div>
                      <div>
                        <div class="col-12 text-left">
                          <span class="float-right">{{ get_round_up_price(room.price) }}</span>
                          {{ numberOfNight }} night (at {{
                            get_currency_symbol(room.currency) || '&euro;'
                          }} <template v-if="isHotDeal"> {{ get_round_up_price(room.price) }}</template> <template v-else>{{ get_round_up_price(room.price / numberOfNight) }}</template> /night, 1 room)
                        </div>
                        <div class="col-12 text-left mt-4">
                          <strong>
                            <span class="float-right">{{ get_round_up_price(amount) }}</span>
                            <span>TOTAL: </span>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="mt-3">
                  <span v-if="isLoading"> <font-awesome-icon icon="spinner" size="1x"
                                                             spin/>
                  </span><span v-else>Pay with Card</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
    <PaymentModal/>
  </div>
</template>

<script>
const style = {
  base: {
    color: "#32325d",
    fontFamily: 'Arial, sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "16px",
    "::placeholder": {
      color: "#32325d"
    }
  },
  invalid: {
    fontFamily: 'Arial, sans-serif',
    color: "#fa755a",
    iconColor: "#fa755a"
  }
};
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from 'vuex'
import {Toast} from "../../utils/mixins/methodMixins";
import dayjs from "dayjs";
import methodMixins from "../../utils/mixins/methodMixins";
import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import PaymentModal from "./PaymentModal";


export default {
  name: "Payment",
  components: {
    Navbar,
    Footer,
    HotelDatePicker,
    PaymentModal
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      nationality: "",
      contact_no: "",
      date_option: '',
      i18n: {
        "night": "Night",
        "nights": "Nights",
        "week": "week",
        "weeks": "weeks",
        "day-names": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
        "check-in": "When",
        "check-out": "When",
        "month-names": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        "tooltip": {
          "halfDayCheckIn": "Available CheckIn",
          "halfDayCheckOut": "Available CheckOut",
          "saturdayToSaturday": "Only Saturday to Saturday",
          "sundayToSunday": "Only Sunday to Sunday",
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum.",
        },
      }

    }
  },
  mixins: [methodMixins],
  methods: {
    validateForm() {

      if (!this.first_name || !this.last_name || !this.nationality || !this.contact_no) {
        Toast.fire({
          icon: 'warning',
          title: 'Validation Error',
          text: 'Complete all field'
        })
        return false
      }
      return true
    },
    async save_room_to_booking(paymentRef) {

      const loader = this.$loading.show({
        container: this.$refs.paymentForm,
        loader: 'bars',
        color: '#000',
        width: 150,
        height: 100
      })

      let cost = this.amount;
      let client_reference = this.client_reference;
      let room_lead_guests = {
        first_name: this.first_name,
        last_name: this.last_name,
        nationality: this.$store.getters['room/get_single_room'].search.source_market
      }

      let contact_person = {
        first_name: this.first_name,
        last_name: this.last_name,
        contact_no: this.contact_no,
        nationality: this.$store.getters['room/get_single_room'].search.source_market,
        email: this.user.data.email
      }
      const response = this.room.createdBy === 'rakuten' ? await this.$store.dispatch('booking/rakuten_hotel_reservation', {
        client_reference,
        room_lead_guests,
        contact_person,
        paymentRef,
        cost
      }) : await this.$store.dispatch('booking/save_room_to_booking', {paymentRef, cost})

      if (response.status) {
          $('#paymentModal').modal('hide')
        loader.hide()
        await Toast.fire({
          icon: 'success',
          title: `Booking Successfully`,
          text: response.message
        });
        await this.$store.commit('room/reset')
        await this.$store.commit('booking/reset')
        await this.$store.commit('hotel/reset')
        await this.$router.replace({name: 'PaymentSuccess'})
      } else {
          $('#paymentModal').modal('hide')
        loader.hide()
        await Toast.fire({
          icon: 'error',
          title: `Booking Failed`,
          text: response.message
        })
      }
    },
    setDate(e, checkin, checkout) {
      this.room.availabilityStart = checkin;
      this.room.availabilityEnd = checkout;
    },

    createPaymentIntent() {

      if (this.validateForm()) {
this.$store.commit('booking/set_loading', true)

        // A reference to Stripe.js initialized with a fake API key.
        // Sign in to see examples pre-filled with your key.
        var stripe = window.Stripe(process.env.VUE_APP_STRIPE_PKEY);

        // The items the customer wants to buy
        var purchase = {
          amount: Math.round(this.amount * 100),
          currency: this.room.currency,
          receipt_email: this.user.data.email,
          description: `Customer Phone Number: ${this.contact_no},  User ID: ${this.user.id}, Room ID: ${this.room.id},Check In Date: ${new Date(this.room.availabilityStart)}, Check Out Date: ${new Date(this.room.availabilityEnd)}`,
        };
        // Disable the button until we have Stripe set up on the page
        //http://localhost:4242/create-payment-intent
        document.querySelector("#submit").disabled = true;
        document.querySelector(".result-message").classList.add("hidden");
        fetch("https://us-central1-havemyroomstaging.cloudfunctions.net/api/v1/create-payment-intent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(purchase)
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.$store.commit('booking/set_loading', false)
            if (!data.client_secret) throw new Error(data.message)
            var elements = stripe.elements();
            var card = elements.create("card", {style: style});
            // Stripe injects an iframe into the DOM
            card.mount("#card-element");
            $('#paymentModal').modal('show')

            card.on("change", (event) => {
              // Disable the Pay button if there are no card details in the Element
              document.querySelector("#submit").disabled = event.empty;
              document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
            });

            var form = document.getElementById("payment-form");
            form.addEventListener("submit", (event) => {
              event.preventDefault();
              // Complete payment when the submit button is clicked
              this.payWithCard(stripe, card, data.client_secret);
            });
          }).catch(err => {
          console.log(err.message)
            Toast.fire({
            icon: 'error',
            title: `Failed To Load Stripe`,
            text: err.message
          })
        })
      }
    },


// Calls stripe.confirmCardPayment
    // If the card requires authentication Stripe shows a pop-up modal to
    // prompt the user to enter authentication details without leaving your page.
    payWithCard(stripe, card, clientSecret) {
      this.loading(true);
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: card,
            billing_details: {
              name: `${this.first_name} ${this.last_name}`
            }
          }
        })
        .then(async (result) => {
          if (result.error) {
            // Show error to your customer
            this.showError(result.error.message);
          } else {
            // The payment succeeded!
            this.orderComplete(result.paymentIntent.id);
            await this.save_room_to_booking(result.paymentIntent.id)

          }
        });
    },


    /* ------- UI helpers ------- */

// Shows a success message when the payment is complete
    orderComplete(paymentIntentId) {
      this.loading(false);
      document
        .querySelector(".result-message a")
        .setAttribute(
          "href",
          "https://dashboard.stripe.com/test/payments/" + paymentIntentId
        );
      document.querySelector(".result-message").classList.remove("hidden");
      document.querySelector("#submit").disabled = true;
    },

// Show the customer the error from Stripe if their card fails to charge
    showError(errorMsgText) {
      this.loading(false);
      var errorMsg = document.querySelector("#card-error");
      errorMsg.textContent = errorMsgText;
      setTimeout(function () {
        errorMsg.textContent = "";
      }, 4000);
    },

// Show a spinner on payment submission
    loading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    },


  },
  computed: {
    ...mapGetters({
      room: 'room/get_single_room',
      user: 'user/get_user',
      isHotDeal: 'room/room_is_hot_deal',
      isLoading: 'booking/isLoading'
    }),
    numberOfNight() {
      const check_in_date = new Date(this.room.availabilityStart)
      const check_out_date = new Date(this.room.availabilityEnd)
      return dayjs(check_out_date).diff(dayjs(check_in_date), 'day');

    },
    amount() {
      if(this.isHotDeal){
        return this.room.price * this.numberOfNight
      }
      return this.room.price
    },
    client_reference() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  mounted() {
    this.$store.commit('booking/set_loading', false)
    this.first_name = this.user.data.fullName.split(' ').slice(0, -1).join(' ')
    this.last_name = this.user.data.fullName.split(' ').slice(-1).join(' ')
    this.contact_no = this.user.data.phone
   this.date_option = Object.freeze({
      availabilityStart: this.room.availabilityStart,
      availabilityEnd: this.room.availabilityEnd,
    });
    if(this.isHotDeal){
      this.i18n["check-in"] = dayjs(this.room.availabilityStart).format("YYYY-MM-DD")
      this.i18n["check-out"] = dayjs(this.room.availabilityEnd).format("YYYY-MM-DD")
    }

  }
}
</script>

<style scoped>

.page-content {
  width: 100%;
  margin:  0 auto;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}


/* Variables */
form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
  0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin-top: 90px;
}

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}


.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

/* Buttons and links */
button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}


@media only screen and (max-width: 600px) {
  form {
    width: 10vw;
    min-width: 300px;
  }
}

</style>
<style>
.vue-country-select .dropdown {
  align-items: flex-start;
}
</style>

import firebase, {usersRef} from '../../config/firebase'

import User from "../../models/user";
import {CustomResponse} from "../../utils/globalObjects";

export default {
  namespaced: true,
  state: {
    user: {id: '', data: {}},
    authType: '',
    loading: false,
    error: false,
    loggedIn: false
  },
  getters: {
    get_user: (state) => state.user,
    get_auth_type: (state) => state.authType,
    has_error: (state) => state.error,
    is_loggedIn(state) {
      firebase.auth().onAuthStateChanged((user) => {
        state.loggedIn = !!user
      })
      return state.loggedIn
    },
    is_loading: (state) => state.loading
  },
  mutations: {
    set_user(state, payload) {
      state.user = payload
    },
    set_auth_type(state, payload) {
      state.authType = payload
    },
    set_loading(state, value) {
      state.loading = value
    },
    set_error(state, value) {
      state.error = value
    },
    reset(state) {
      state.user = {id: '', data: {}},
        state.authType = '',
        state.error = false
    }
  },
  actions: {
    async init_user({commit}, result) {
      return usersRef.doc(result.user.uid).get()
        .then(doc => {
          if (doc.exists && (doc.data().type === 'user' || doc.data().type === 'admin') ) {
            commit('set_user', {id: doc.id, data: doc.data()})
            commit('set_error', false)
            return Promise.resolve({code: 200, status: true, message: 'Log in Successful'})
            // eslint-disable-next-line no-throw-literal
          } else return Promise.resolve({message: 'User does not exist or is not properly authenticated', status: false, code: 204})
        }).catch(err => {
          commit('set_error', true)
          console.log('this is', err.message)
          return Promise.resolve({message: err.message, status: false, code: 400})
        })
    },

    async refresh_user_details({commit}, userID) {
      return usersRef.doc(userID).get()
        .then(doc => {
          if (doc.exists) {
            commit('set_user', {id: doc.id, data: doc.data()})
          }
        }).catch(err => {
          console.log('this is', err.message)
        })
    },

    async add_user(context, {userCredential, userDetails, userDeviceToken}){

      const response = new CustomResponse()
      userCredential.user.photoURL === null ? userDetails.pictureURL = '' : userDetails.pictureURL = userCredential.user.photoURL
      const tmp_user = new User({
        id: userCredential.user.uid,
        data: userDetails
      })
   userDeviceToken.length > 0 ?  tmp_user.data.deviceTokens.push(userDeviceToken) : tmp_user.data.deviceTokens;



      return tmp_user.create().then(() => {
        response.message = "Account Successfully Created"
        return Promise.resolve(response)
      })
        .catch((e) => {
          console.log(e.message)
          response.message = e.message
          response.status = false
          return Promise.resolve(response)
        })
    },

    updateUser({dispatch}, user){

      const tmp_user = new User({
        id: user.id,
        data: user.data
      })
      return tmp_user.update().then(() => {
        dispatch('refresh_user_details', user.id)
        return Promise.resolve({message: 'Successfully Updated Profile', status: true})
      })
        .catch((e) => {
          return Promise.resolve({message: `${e.message}`, status: false})
        })
    },
    async reset({commit}) {
      commit('reset')

    }
  }
}

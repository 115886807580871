<template>
    <div>
        <!-- <div class="loader">
          <div class="loader__figure"></div>
        </div> -->

        <svg class="hidden">
            <svg id="icon-nav" viewBox="0 0 152 63">
                <title>navarrow</title>
                <path
                    d="M115.737 29L92.77 6.283c-.932-.92-1.21-2.84-.617-4.281.594-1.443 1.837-1.862 2.765-.953l28.429 28.116c.574.57.925 1.557.925 2.619 0 1.06-.351 2.046-.925 2.616l-28.43 28.114c-.336.327-.707.486-1.074.486-.659 0-1.307-.509-1.69-1.437-.593-1.442-.315-3.362.617-4.284L115.299 35H3.442C2.032 35 .89 33.656.89 32c0-1.658 1.143-3 2.552-3H115.737z"/>
            </svg>
        </svg>

        <nav id="menu-wrap" class="cbp-af-header menu-back">

            <div class="menu-top background-black">
                <div class="container">
                    <div class="row">
                        <div class="col-6 px-0 px-md-3 pl-1 py-3">
                            <span class="call-top">call us:</span> <a href="javascript:void(0)" class="call-top">+353 857 862 720</a>
                        </div>
                        <div class="col-6 px-0 px-md-3 py-3 text-right">
                            <router-link v-if="!isLogged" :to="{name: 'login'}" class="social-top">Login</router-link>
                            <router-link v-if="isLogged" :to="{name: 'profile'}" class="social-top"><img src="assets/img/profile.jpg"
                                                                                                         style="border-radius:50%; width:20px; height:20px"
                                                                                                         alt=""> Profile</router-link>
                            <a v-if="isLogged" @click="logout" href="javascript:void(0)" class="social-top">Logout</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu">
                <router-link :to="{name: 'Home'}">
                    <div class="logo">
                        <img src="assets/img/logo (2).png" alt="">
                    </div>
                </router-link>
                <ul>
                    <li>
                        <router-link :to="{name: 'Home'}">Home</router-link>
                    </li>
                    <li class="menu-dropdown-icon">
                        <a href="javascript:void(0)" class="normal-sub">Rooms</a>
                        <ul>
                            <li><router-link :to="{name: 'rooms'}">My Rooms</router-link></li>
                            <li> <router-link :to="{name: 'post_rooms'}">Post Room</router-link></li>
                            <li><router-link :to="{name: 'HotDeals'}">Hot Deals</router-link></li>
                            <li><router-link :to="{name:'bookings'}">My bookings</router-link></li>
                        </ul>
                    </li>
                    <li>
                        <router-link :to="{name: 'about'}">About Us</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'blog'}">News</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'contact'}">Contact us</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'faq'}">Faqs</router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'HotDeals'}"><span style="background-color:goldenrod">Hot Deals</span></router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import firebase from "../config/firebase";
import {mapActions} from 'vuex'

export default {
    name: 'Navbar',
    data() {
        return {
            isLogged: false
        }
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        })
    },
    created() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.isLogged = true
                // update data or vuex state
            } else {
                this.isLogged = false
            }
        });
    },
    mounted(){
        window.addEventListener("scroll", function(){
            var header = document.querySelector(".cbp-af-header");
            var head = this.document.querySelector(".menu-top");
            header.classList.toggle("scroll-active", window.scrollY > 4);
            head.classList.toggle("scroll", window.scrollY > 4);
        });

        $('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
        //Checks if li has sub (ul) and adds class for toggle icon - just an UI


        $('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
        //Checks if drodown menu's li elements have anothere level (ul), if not the dropdown is shown as regular dropdown, not a mega menu (thanks Luka Kladaric)

        $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\"></a>");

        //Adds menu-mobile class (for mobile toggle menu) before the normal menu
        //Mobile menu is hidden if width is more then 1199px, but normal menu is displayed
        //Normal menu is hidden if width is below 1199px, and jquery adds mobile menu
        //Done this way so it can be used with wordpress without any trouble

        $(".menu > ul > li").hover(function (e) {
            if ($(window).width() > 1170) {
                $(this).children("ul").stop(true, false).fadeToggle(300);
                e.preventDefault();
            }
        });
        //If width is more than 1170px dropdowns are displayed on hover

        $(".menu > ul > li").on('click', function () {
            if ($(window).width() <= 1170) {
                $(this).children("ul").fadeToggle(300);
            }
        });
        //If width is less or equal to 1170px dropdowns are displayed on click

        $(".menu-mobile").on('click', function (e) {
            $(".menu > ul").toggleClass('show-on-mobile');
            e.preventDefault();
        });
        //when clicked on mobile-menu, normal menu is shown as a list, classic rwd menu stor
    }
}
</script>
<style scoped>
.scroll{
    display: none;
}
.scroll-active {
    background-color: rgb(19, 18, 18);
}
</style>

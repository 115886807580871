<template>
    <div class="home">
        <Navbar/>
        <div class="section background-dark">
            <div class="hero-center-section">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-10 col-sm-8 mt-5 parallax-fade-top">
                            <div class="hero-text">Where are you headed to ?</div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="booking-hero-wrap">
                                <div class="row justify-content-center">
                                    <div class=" col-lg-4 col-md-4 col-sm-4" id="light">
                                        <!--  <input class="col-12" type="text" name="location" placeholder="Location" id=""> -->
                                        <vue-google-autocomplete
                                            ref="address"
                                            id="map"
                                            placeholder="Enter Your Destination"
                                            v-on:placechanged="getAddressData"
                                            types="(cities)"
                                        >
                                        </vue-google-autocomplete>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="input-group">

                                            <div class="row">
                                                <div class="col-12">

                                                    <HotelDatePicker class="myDate"
                                                                     :startDate="new Date()"
                                                                     :displayClearButton=false
                                                                     @period-selected="setDate"
                                                                     :i18n="i18n"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 col-sm-4 ">
                                        <div class="row">
                                            <div class="col-6">
                                                <select v-model.number="adultsCount"  name="adults" class="form-select">
                                                    <option value="">adults</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>

                                            <div class="col-6" @click="toggle =true">
                                                <select v-model="childrenList"   name="children" class="form-select">
                                                    <option value="">children</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2  col-sm-4 col-lg-1">
                                        <a class="booking-button" style="background-color:goldenrod" @click.prevent="searchHotels"
                                           href="javascript:void(0)"><span v-if="loading"> <font-awesome-icon icon="spinner" size="1x"
                                                                                                              spin/></span>
                                            <span v-else>Search</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="childAge.length > 0" style="background-color:black;padding-top:10px; width:25%;margin-left:48px">
                    <div class="container" v-for="(age, index) in childAge" :key="index">
                        <div class="row mb-2" style="">
                            <!--                  <div class="col-2"> &lt;!&ndash;- -&ndash;&gt;</div>-->
                            <div class="col-6"><p
                                style="color: white; font-weight:bold; margin-left:10px; font-size:16px;"> Child {{ index + 1 }} Age</p>
                            </div>
                            <div class="col-6" style="margin-left:-15px">
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="decrementNumber(index, age)"
                                        :disabled="childAge[index] === 0"
                                    >
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input type="text" style="max-width: 50px; background: white" v-model="childAge[index]" disabled/>
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="incrementNumber(index, age)"
                                        :disabled="childAge[index] === 17"
                                    >
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row justify-content-center">
                        <a href="https://apps.apple.com/app/id1523231840" target="_blank" class="btn app-btn solid-white mt-4">
                            <i class="ti-apple"></i> App Store
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.havemyroom.havemyroomapp" target="_blank" class="btn app-btn outline-white ml-5 mt-4">
                            <i class="ti-android"></i> Play Store
                        </a>
                    </div>
                </div>
            </div>
            <div class="section">
                <!-- <div class="customNavigation rooms-sinc-1-2">
                  <a class="prev-rooms-sync-1"></a>
                  <a class="next-rooms-sync-1"></a>
                </div> -->
                <div id="rooms-sync3" class="owl-carousel slideshow">
                    <div class="item">
                        <img src="assets/img/image4.jpeg" alt="">
                    </div>
                    <div class="item">
                        <img src="assets/img/image7.jpeg" alt="">
                    </div>
                    <div class="item">
                        <img src="assets/img/image3.jpeg" alt="">
                    </div>
                </div>

            </div>
            <div class="container-fluid nav fade-top "
                 style="background-color:black; height:100px; padding-top: 20px; margin-bottom:-45px">
                <h2 class="nav__chapter">Because we care</h2>
            </div>
        </div>

        <div class="section padding-top-bottom over-hide background-grey">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 align-self-center">
                        <div class="subtitle with-line text-center mb-4">Have My Room</div>
                        <h3 class="text-center padding-bottom-small">Hot Deals</h3>
                    </div>
                    <div class="section clearfix"></div>
                    <template v-if="loading">
                        <div class="col-md-6 mt-4">
                            <h5>Fetching Hot Deals</h5>
                        </div>

                    </template>

                    <template v-else-if="roomsInHotDeals.length > 0">
                        <div class="col-md-6 mt-3" v-for="(room, index) in roomsInHotDeals" :key="index">
                            <div class="room-box background-white">
                                <div class="room-name">{{ room.data.name }}</div>

                                <img :src="room.data.picturesURL[0]" alt="">
                                <div class="room-box-in">
                                    <h5 class="">{{ room.data.name }}</h5>
                                    <p class="mt-3">{{ room.data.roomDescription.split(" ").splice(0, 20).join(" ") }}</p>
                                    <p>Total stay Price: &euro;{{
                                            room.data.price
                                        }}</p>
                                    <div class="room-icons mt-4 pt-4">
                                        <img src="assets/img/5.svg" alt="">
                                        <img src="assets/img/2.svg" alt="">
                                        <img src="assets/img/3.svg" alt="">
                                        <img src="assets/img/1.svg" alt="">
                                        <a href="javascript:void(0)" @click="update_single_room(room)">full info</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-else>
                        <div class="col-md-6 mt-4">
                            <h5>No Hot Deals Available</h5>
                        </div>

                    </template>

                </div>
            </div>
        </div>


        <Footer/>
    </div>

</template>

<script>
import Navbar from "./Navbar";
import Footer from "./Footer";
import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';

import {Toast} from "../utils/mixins/methodMixins";


import VueGoogleAutocomplete from 'vue-google-autocomplete'
import {mapGetters} from "vuex";
import RoomModel from "../models/room_model";

export default {
    name: 'Home',
    data() {
        return {
            toggle: false,
            checkIn: '',
            checkOut: '',
            adultsCount: '',
            childrenList: '',
            childAge: [],
            longitude: '',
            latitude: '',
            address_text: '',
            i18n: {
                "night": "Night",
                "nights": "Nights",
                "week": "week",
                "weeks": "weeks",
                "day-names": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
                "check-in": "When",
                "check-out": "When",
                "month-names": [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ],
                "tooltip": {
                    "halfDayCheckIn": "Available CheckIn",
                    "halfDayCheckOut": "Available CheckOut",
                    "saturdayToSaturday": "Only Saturday to Saturday",
                    "sundayToSunday": "Only Sunday to Sunday",
                    "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum.",
                },
            }
        }

    },
    components: {
        Navbar,
        Footer,
        VueGoogleAutocomplete,
        HotelDatePicker
    },
    watch: {
        childrenList: function (newValue) {
            if (newValue !== '') {
                var age = new Array(parseInt(newValue)).fill(0)
                this.childAge = age
            } else {
                this.childAge.length = 0
            }
        }
    },
    computed: {
        ...mapGetters({
            loading: 'room/room_is_loading',
        }),
        roomsInHotDeals() {
            return this.$store.getters['room/get_hotel_deals'].slice(0, 4)
        }
    },
    methods: {
        getAddressData: function (addressData) {
            let searched_address = addressData.locality + ", " + addressData.country;

            this.latitude = addressData.latitude
            this.longitude = addressData.longitude
            this.address_text = searched_address
        },
        async update_single_room(room) {
            const room_in_hot_deal = new RoomModel()
            room_in_hot_deal.FromFirestore(room.data)
            await this.$store.commit('room/set_room_is_hot_deal', true)
            await this.$store.commit('room/set_single_room', room_in_hot_deal)
            await this.$router.push({name: 'roomsgallery'})
        },
        async searchHotels() {
            if (!this.validateForm()) {
                return
            }
            let search_params = {
                latitude: this.latitude,
                longitude: this.longitude,
                checkIn: this.checkIn,
                checkOut: this.checkOut,
                adultsCount: this.adultsCount,
                childrenList: this.childAge,
                address_text: this.address_text,
                select_child_age: this.childrenList
            }

            await this.$store.dispatch('room/searchForRooms', search_params)

            if (this.$store.getters['room/room_has_error']) {
                await Toast.fire({
                    icon: 'error',
                    title: this.$store.getters['room/get_error_message']
                })
                this.$store.commit('room/reset')
            } else if (this.$store.getters['room/room_is_empty']) {
                await Toast.fire({
                    icon: 'info',
                    title: "No result found"
                })
                this.$store.commit('room/reset')
            } else {
                await this.$router.push({name: 'HotelSearchResult'})
            }


        },
        validateForm() {
            if (!this.checkIn || !this.checkOut) {
                Toast.fire({
                    icon: 'info',
                    title: 'Select Check In and Check Out Dates'
                })
                return false
            } else if (!this.latitude || !this.longitude) {
                Toast.fire({
                    icon: 'info',
                    title: 'Please Select a Location'
                })
                return false
            } else if (!this.adultsCount) {

                Toast.fire({
                    icon: 'info',
                    title: 'Please Select number of Adult'
                })
                return false
            }
            return true
        },
        incrementNumber(index) {
            this.$nextTick().then(() => {
                // Add the component back in
                this.childAge[index]++
                this.$forceUpdate();

            });

        },
        decrementNumber(index) {

            this.$nextTick().then(() => {
                // Add the component back in
                this.childAge[index]--
                this.$forceUpdate();
            });
        },
        setDate(e, checkin, checkout) {
            this.checkIn = checkin;
            this.checkOut = checkout
        }
    },
    mounted() {
        this.$store.dispatch('room/searchForHotDeals')
        this.$nextTick(() => {
            this.$refs.address.focus()
            this.$store.commit('room/reset')
            this.$store.commit('hotel/reset')
            this.$store.commit('booking/reset')
        });

        let sync2 = $("#rooms-sync2");
        let sync3 = $("#rooms-sync3");
        sync3.owlCarousel({
            singleItem: true,
            slideSpeed: 400,
            transitionStyle: "goDown",
            pagination: false,
            autoPlay: 5000,
            afterAction: syncPosition
        });
        $("#owl-sep-2").owlCarousel({
            navigation: false,
            pagination: false,
            slideSpeed: 500,
            paginationSpeed: 500,
            items: 7,
            itemsDesktop: [1000, 4],
            itemsDesktopSmall: [900, 3],
            itemsTablet: [600, 2],
            itemsMobile: false,
            autoPlay: 5000
        })

        // eslint-disable-next-line no-unused-vars
        function syncPosition(el) {
            var current = this.currentItem;
            sync2
                .find(".owl-item")
                .removeClass("synced")
                .eq(current)
                .addClass("synced")
            if (sync2.data("owlCarousel") !== undefined) {
                center(current)
            }
        }

        function center(number) {
            var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
            var num = number;
            var found = false;
            for (var i in sync2visible) {
                if (num === sync2visible[i]) {
                    found = true;
                }
            }
            if (found === false) {
                if (num > sync2visible[sync2visible.length - 1]) {
                    sync2.trigger("owl.goTo", num - sync2visible.length + 2)
                } else {
                    if (num - 1 === -1) {
                        num = 0;
                    }
                    sync2.trigger("owl.goTo", num);
                }
            } else if (num === sync2visible[sync2visible.length - 1]) {
                sync2.trigger("owl.goTo", sync2visible[1])
            } else if (num === sync2visible[0]) {
                sync2.trigger("owl.goTo", num - 1)
            }

        }
    }
}
</script>
<style scoped>
#light input::-webkit-input-placeholder,
#light-datepicker-1 input::-webkit-input-placeholder {
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#light input:-moz-placeholder,
#light input:-moz-placeholder {
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#light input:-ms-input-placeholder,
#light input:-ms-input-placeholder {
    color: black;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
}



#light label,
#light label {
    display: block;
    color: white;
}

#light input,
#light input {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    line-height: 1.2;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
    font-weight: 300;
    text-align: left;
    color: #000000;
    border: solid 1px rgba(255, 255, 255, .1);
    outline: none;
    /* cursor: pointer; */
    background-color: #FFFFFF;
    transition: border-color .2s ease-out;
}

.myDate {
    min-width: 350px;
}

#rooms-sync3 .item img{
    min-height: 680px;
}

select.form-select {
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.0;
    color: #000000;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}
.app-btn{
    border-radius: 30px;
    padding: 12px 30px;
    font-weight: 700;
}
.solid-white{
    background: #ffffff;
    border: 1px solid #ffffff;
    color: #122620;
}
.solid-white:hover{
    background: #DAA520;
    border-color: #DAA520;
    color: #ffffff;
}
.outline-white{
    color: #ffffff;
    border: 1px solid #DAA520;
    background-color: #DAA520;
}
.outline-white:hover{
    background: #FFFFFF;
    border-color: #FFFFFF;
    color: #122620;
}
</style>

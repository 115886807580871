const axios = require('axios');

class Response {
  constructor (status = true, message = '', data = {}) {
    this.status = status
    this.message = message
    this.data = data
  }

  set_status(value, err=null){
    this.status = value;
    this.message = JSON.stringify(err.message || err.statusText || err)
  }
}

/*
let HMR_URL;
if(window.location.host === 'havemyrooom.web.app' || window.location.hostname === 'localhost'){
  HMR_URL = 'http://localhost:5001/havemyroomstaging/us-central1/api/v1/'
}else{
  HMR_URL = 'https://us-central1-havemyroomstaging.cloudfunctions.net/api/v1/'
}
*/
let HMR_URL = 'https://us-central1-havemyroomstaging.cloudfunctions.net/api/v1/'
const HMR_Instance = axios.create({
  baseURL: HMR_URL,
  headers: {
    "authorization": process.env.VUE_APP_HMR_AUTHORIZATION,
  }
})

export const CustomResponse = Response
export const HMRInstance = HMR_Instance


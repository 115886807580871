import {firestore} from '../config/firebase'
import {geo} from "../config/geofirex";

class HotelModel {
    constructor () {
        this.id = '';
        this.name = '';
        this.address = '';
        this.geoLocation = {};
        this.picturesURL = [];
        this.rating = 0;
        this.hotelDescription = '';
        this.hotelPhoneNumber = '';
        this.currency = '';
        this.price = 0;
        this.isVisible = true;
        this.isHotDeal = false;
        this.createdBy = "";
        this.bookMarkers = [];
        this.rooms = [];
        this.roomCount = 0;
        this.createdAt = firestore.FieldValue.serverTimestamp();
        this.updatedAt = firestore.FieldValue.serverTimestamp()
    }

    FromImpala(impalaHotel){
        this.id = impalaHotel.hotelId;
        this.name = impalaHotel.name;
        this.address = impalaHotel.address.line1 + " " + impalaHotel.address.city;
        this.geoLocation = geo.point( impalaHotel.location.latitude,impalaHotel.location.longitude);
        this.picturesURL = impalaHotel.images.map((image) => image.url);
        this.rating = Number.parseFloat(impalaHotel.starRating);
        this.hotelDescription = impalaHotel.description.short;
        this.hotelPhoneNumber = impalaHotel.phoneNumbers[0]?impalaHotel.phoneNumbers[0]:''
        this.price = 0;
        if(impalaHotel.roomTypes.length > 0){
            this.price = Number.parseFloat(impalaHotel.roomTypes[0].rates[0].retailRate.total.amount / 100)
        }
        this.currency = impalaHotel.currency;
        this.isVisible = true;
        this.isHotDeal = false;
        this.createdBy = "impala";
        this.bookMarkers = [];
        this.createdAt = firestore.Timestamp.fromDate(new Date(impalaHotel.createdAt));
        this.updatedAt = firestore.Timestamp.fromDate(new Date(impalaHotel.updatedAt));
    }

  FromRakuten(rakuten){
    this.id = rakuten.id;
    this.name = rakuten.name;
    this.address = rakuten.address;
    this.geoLocation = rakuten.geoLocation;
    this.picturesURL = rakuten.picturesURL;
    this.rating = rakuten.rating;
    this.hotelDescription = rakuten.hotelDescription;
    this.hotelPhoneNumber = rakuten.hotelPhoneNumber;
    this.price = rakuten.price;
    this.currency = 'EUR';
    this.isVisible = rakuten.isVisible;
    this.isHotDeal = rakuten.isHotDeal;
    this.createdBy = rakuten.createdBy;
    this.bookMarkers = rakuten.bookMarkers;
    this.createdAt = firestore.Timestamp.fromDate(new Date(rakuten.createdAt));
    this.updatedAt = firestore.Timestamp.fromDate(new Date(rakuten.updatedAt));
  }
}

export default HotelModel

import firebase, { roomsRef } from '../config/firebase'

class Room {
  constructor ({  data = {} }) {
    this.data = Object.assign(new Model(), data)
    this.data.availabilityStart = firebase.firestore.Timestamp.fromDate(new Date(this.data.availabilityStart))
    this.data.availabilityEnd = firebase.firestore.Timestamp.fromDate(new Date(this.data.availabilityEnd))
  }

  create () {
    return roomsRef.doc().set(Object.assign({}, this.data))
  }
}

function Model () {
  this.address = ''
  this.amenities = []
  this.availabilityEnd = firebase.firestore.Timestamp.fromDate(new Date(this.availabilityEnd))
  this.availabilityStart = firebase.firestore.Timestamp.fromDate(new Date(this.availabilityStart))
  this.bookMarkers = []
  this.createdAt = firebase.firestore.FieldValue.serverTimestamp()
  this.createdBy = ''
  this.currency = 'EUR'
  this.geoLocation = {}
  this.hotelName = ''
  this.hotelPhoneNumber = ''
  this.initialBookingPoint = ''
  this.isHotDeal = false
  this.isVisible = true
  this.name = ''
  this.originalPrice = 0
  this.picturesURL = []
  this.price = 0
  this.rating = 0
  this.roomDescription = ''
  this.status = ''
  this.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
}

 export const Room_Model = Model
export default Room

<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide z-bigger" style="height:250px">

      <div class="parallax parallax-top" style="background-image: url('assets/img/image7.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">Terms &amp; Conditions</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom z-bigger">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 align-self-center">
            <div class="subtitle with-line text-center mb-4">frequently asked questions</div>
            <h3 class="text-center padding-bottom-small">General queries</h3>
          </div>
          <div class="section clearfix"></div>
          <div class="col-lg-8">
            <div id="accordion">
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="m-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="true" aria-controls="collapseTwo">
                      How do we contact you?
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                   We are available 24/7 using WhatsApp or email.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading4">
                  <h5 class="m-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4"
                            aria-expanded="false" aria-controls="collapse4">
                     What payment methods are available and how do I choose one?
                    </button>
                  </h5>
                </div>
                <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                  <div class="card-body">
                   You can get pay by Bank transfer or PayPal
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading5">
                  <h5 class="m-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5"
                            aria-expanded="false" aria-controls="collapse5">
                    How does Havemyroom secure my payment?
                    </button>
                  </h5>
                </div>
                <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                  <div class="card-body">
                    Havemyroom uses the most advanced processing system run by Stripe.com to ensure your payment is
                completely secure. We never store your credit card information and only accept 100% verified
                payments.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                      How does Havemyroom verify the reservation?
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    Havemyroom advanced technology identifies and either accepts or rejects the validity of every
                reservation listed for sale on our marketplace. Thanks to this technology, we can state with total
                confidence that each and every room that you see on our platform is 100% authentic, valid and bookable.
                Still not sure? Contact the hotel directly and we are always available through WhatsApp.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading3">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse3"
                            aria-expanded="false" aria-controls="collapseThree">
                       What types of reservations does Havemyroom offer?
                    </button>
                  </h5>
                </div>
                <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#accordion">
                  <div class="card-body">
                    <p> We offer three types of reservation: </p>
                    <ol class="pl-4" style="list-style-type: lower-alpha;">
                      <li> Hotel rooms provided by a traveller who cannot make it any longer</li>
                      <li> Hotel rooms provided by our Hotel Partners</li>
                      <li> Hotel rooms provided by a traveller who had to check out early</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading9">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse9"
                            aria-expanded="false" aria-controls="collapse9">
                      Is my credit card information secure?
                    </button>
                  </h5>
                </div>
                <div id="collapse9" class="collapse" aria-labelledby="heading9" data-parent="#accordion">
                  <div class="card-body">
                    You bet. We are PCI compliant, every credit card payment on Havemyroom is processed through a 3rd
                party(www.stripe.com), fully encrypted server. In other words your information is safe with us.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading10">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse10"
                            aria-expanded="false" aria-controls="collapse10">
                      I have sold my reservation, when do I get paid?
                    </button>
                  </h5>
                </div>
                <div id="collapse10" class="collapse" aria-labelledby="headingThree10" data-parent="#accordion">
                  <div class="card-body">
                   You are anxious to get paid and we don't blame you. We want you to get your money as soon as possible.
                You will get pay 7 days after your reservation’s check-out date. The transfer time can vary depending on
                the payment method selected and depending on your bank.</div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading11">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse11"
                            aria-expanded="false" aria-controls="collapse11">
                      Why do I get paid 7 days after check-out?
                    </button>
                  </h5>
                </div>
                <div id="collapse11" class="collapse" aria-labelledby="heading11" data-parent="#accordion">
                  <div class="card-body">
                   We are dedicated to protecting our customers and keeping the Havemyroom marketplace secure. In order
                to avoid fraud and ensure that everyone can use the platform with complete confidence, we hold the
                payment until 7 days after the check-out date. This allows us to confirm that the new guest was able to
                check-in and check-out without any problems.</div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading12">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse12"
                            aria-expanded="false" aria-controls="collapse12">
                      The payment I received for my reservation doesn’t seem right
                    </button>
                  </h5>
                </div>
                <div id="collapse12" class="collapse" aria-labelledby="heading12" data-parent="#accordion">
                  <div class="card-body">
                  Remember that Havemyroom charges a 15% service fee for promoting and hosting your hotel room listing.
                Each payment method also incurs its own fees, and your bank may charge additional fees. If your payment
                still does not look right, contact us and we’ll sort it out.</div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading13">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse13"
                            aria-expanded="false" aria-controls="collapse13">
                      What is a Hot deal?
                    </button>
                  </h5>
                </div>
                <div id="collapse13" class="collapse" aria-labelledby="heading13" data-parent="#accordion">
                  <div class="card-body">
                  You will not find a cheaper deal somewhere else. A client who cannot complete his/her booking on the
                property may choose to list the remaining days with us and the price will always be much cheaper than
                the market value</div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading7">
                  <h5 class="m-0">
                    <button class="btn btn-link last collapsed" data-toggle="collapse" data-target="#collapse7"
                            aria-expanded="false" aria-controls="collapse7">
                    How can we pay?
                    </button>
                  </h5>
                </div>
                <div id="collapse7" class="collapse" aria-labelledby="heading7" data-parent="#accordion">
                  <div class="card-body last">
                    We accept Visa, Mastercard and PayPal. Sometimes you may be able to pay directly at the Hotel.
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";

export default {
  name: 'Terms',
  components: {
    Navbar,
    Footer
  }
}
</script>

<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide z-bigger" style="height:250px">

      <div class="parallax parallax-top" style="background-image: url('assets/img/image7.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">Our News</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top padding-bottom-smaller z-bigger background-grey">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="subtitle with-line text-center mb-4">Have My Room</div>
            <h3 class="text-center padding-bottom-small">latest news</h3>
          </div>

          <div class="section clearfix"></div>
          <div class="col-md-6 col-xl-4">
            <div class="room-box background-white" v-for="dot in news" v-bind:key="dot.id">
              <img :src="dot.images[1]" alt="" style="height:250px">
              <div class="room-box-in">
                <h6 class="" style="color:black; font-weight:bold">{{ dot.title }}</h6>
                <p class="mt-3">{{dot.body.split(" ").splice(0, 17).join(" ")}}</p>
                <router-link class="mt-1 btn btn-primary" v-bind:to="{name:'newsdetail', params:{id: dot.id}}">
                  read more
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import Footer from "./Footer";
 import {newsRef} from '../config/firebase'
// import {mapGetters, mapActions} from "vuex"

export default {
  name: 'Blog',
  components: {
    Navbar,
    Footer
  },
  data(){
    return{
      news:[],
    }
  },
  // computed: mapGetters(['rooms_news']),

  methods:{
    // ...mapActions(['getNews']),
     },
  created(){

  return  newsRef.get().then(
      querySnapshot =>{
            querySnapshot.forEach(doc =>{
                console.log(doc);
                const data = {
                    'id': doc.id,
                    'body': doc.data().body,
                    'images': doc.data().images,
                    'title': doc.data().title,

                }
                this.news.push(data);
            })
  })
  },

}
</script>

<template>
    <div>
        <Navbar/>
        <div class="section">
            <div class="page-content" style="background-image: url('assets/img/image5.jpeg')">
                <div class="wizard-v10-content">
                    <div class="wizard-form">
                        <div class="container">
                            <div class="row justify-content-center" style="margin-top: 50px; margin-bottom: 10px;">
                                <!-- <div class=" col-lg-2 col-md-2 col-sm-2"></div> -->
                                <div class=" col-lg-12 col-md-12 col-sm-12 text-center"  ref="login">
                                    <div class=" col-md-12 col-sm-12 pt-4">
                                        <h5>Login to enjoy the full experience</h5>
                                        <p class=" mt-2">
                                            see your account info, bookings, bookmarks, etc.
                                        </p>
                                    </div>
                                    <!-- Form -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-left col-lg-12 col-md-12 col-sm-12" style="color:gray">Email:</label>
                                            <input class="form-control" v-model="email" type="text" placeholder="Email"><br>
                                            <!--	<span style="color:red; text-transform:capitalize; ">{{display}}</span> -->
                                        </div>
                                        <div class="form-group">
                                            <label class="text-left col-lg-12 col-md-12 col-sm-12" style="color:gray">Password:</label>
                                            <input class="form-control" v-model="password" type="password" placeholder="Password">
                                            <!--	<span style="color:red; text-transform:capitalize;">{{displays}}</span> -->
                                        </div>
                                        <div class="form-group">
                                            <button class="btn btn-primary btn-block" @click.prevent="sign_in_with_email_and_password" type="submit">Sign
                                                in
                                            </button>
                                        </div>
                                    </div>
                                    <!-- </form> -->

                                    <div class="text-center forgotpass col-md-12 col-sm-12"><router-link :to="{name: 'PasswordReset'}">Forgot Password?</router-link></div>
                                    <div class="login-or col-md-12 col-sm-12">
                                        <span class="or-line"></span>
                                        <span class="span-or">or</span>
                                    </div>
                                    <!-- Social Login -->
                                    <div class="text-center social-login col-md-12 col-sm-12">
                                        <button class="google pr-4 pl-4" @click.prevent="google_sign_in"><i class="fab fa-google mr-3"></i>
                                            Continue with Google
                                        </button>
                                        <br>
                                        <button class="apple pr-4 pl-4" @click.prevent="apple_sign_in"><i class="fab fa-apple mr-3"></i> Continue
                                            with Apple
                                        </button>
                                    </div>
                                    <!-- /Social Login -->

                                    <div class="text-center dont-have col-md-12 col-sm-12">Don’t have an account? <router-link :to="{name: 'signup'}">Sign up</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {Toast, validateEmail} from "../../utils/mixins/methodMixins";

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    components: {
        Navbar,
        Footer
    },

    methods: {
        async sign_in_with_email_and_password() {
            if (!this.validateForm()) {
                return
            }
            const loader = this.$loading.show({container: this.$refs.login})
            let email = this.email;
            let password = this.password
            let response = await this.$store.dispatch('auth/login_with_email_and_password', {
                email,
                password
            })
            console.log('this is response', response)
            if (response.code === 200) {
                loader.hide()
              await this.$router.go(-1)
              await Toast.fire({
                icon: 'success',
                title: `${response.message}`
              })

            } else {
                loader.hide()
                await Toast.fire({
                    icon: 'error',
                    title: `${response.message}`
                })

            }
        },

        async google_sign_in() {
            let response = await this.$store.dispatch('auth/sign_in_with_google')
            if (response.status) {
                await this.$router.go(-1)
              await Toast.fire({
                icon: 'success',
                title: `${response.message}`
              })
            } else {
                await Toast.fire({
                    icon: 'error',
                    title: `${response.message}`
                })
            }

        },

        async apple_sign_in() {
            let response = await this.$store.dispatch('auth/sign_in_with_apple')
            if (response.status) {
                await this.$router.go(-1)
              await Toast.fire({
                icon: 'success',
                title: `${response.message}`
              })
            }

            else
            {
                await Toast.fire({
                    icon: 'error',
                    title: `${response.message}`
                })
            }

        },

        validateForm() {
            if (!this.email || !this.password) {
                Toast.fire({
                    icon: 'error',
                    title: 'complete all fields'
                })
                return false
            } else if (!validateEmail(this.email)) {
                Toast.fire({
                    icon: 'error',
                    title: 'Invalid Email Address'
                })
                return false
            } else if (this.password.length < 6) {
                Toast.fire({
                    icon: 'error',
                    title: 'Password must be upto 6 characters'
                })
                return false
            }
            return true
        }

    }
}
</script>

<style scoped>
.login-b {
    background-color: goldenrod;
    height: 130vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.loginbox {
    width: 40%;
    height: 90%;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 100px;
    margin-bottom: 1000px;
    background-color: white;;
    border: 2px solid white;
    vertical-align: middle;
}

.account-subtitle {
    color: #4c4c4c;
    font-size: 17px;
    margin-bottom: 10px;
    text-align: center;
}

.forgotpass a {
    color: #a0a0a0;
}

.forgotpass a:hover {
    color: #333;
    text-decoration: underline;

}

.dont-have {
    color: #a0a0a0;
    margin-top: 1.875rem;
}

.dont-have a {
    color: #333;
}

.dont-have a:hover {
    text-decoration: underline;
}

input {
    border-radius: 10px;
    opacity: 0.5;
    background-color: white;
}

input:hover {
    background-color: white;
}


.social-login button {
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    padding-bottom: 5px;
    padding-top: 5px;
}

.social-login a {
    color: white;
}

.social-login .apple {
    background-color: black;
    border: none;
}

.social-login .apple:hover {
    cursor: pointer;
    background-color: #676464;
}

.social-login .google {
    background-color: #d11c08;
    margin-bottom: 20px;
    border: none;
}

.social-login .google:hover {
    cursor: pointer;
    background-color: #8c1306;
}

.login-or {
    color: #a0a0a0;
    margin-bottom: 15px;
    margin-top: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}

.or-line {
    background-color: #e5e5e5;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}

.span-or {
    background-color: #fff;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 42px;
}

.btn-primary {
    background-color: goldenrod;
    padding-left:  75px;
    padding-right: 75px;
    color: white;
    border-radius: 10px;
    border: none;
}

.btn-primary:hover {
    background-color: gray;
    border: none;
}

.page-content {
    width: 100%;
    margin:  0 auto;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -o-align-items: center;
    -ms-align-items: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -o-background-size: cover;
    -ms-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
}
.wizard-v10-content   {
    background: #fff;
    width: 650px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    margin: 260px 0 160px;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    display: flex;
    display: -webkit-flex;
}
.wizard-v10-content .wizard-form {
    width: 100%;
    border-radius: 8px;
    -o-border-radius: 8px;
    -ms-border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
}

</style>

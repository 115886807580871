<template>
<div>
    <Navbar/>
    <div class="section big-55-height over-hide z-bigger" style="height:250px">

		<div class="parallax parallax-top" style="background-image: url('assets/img/image7.jpeg')"></div>
		<div class="dark-over-pages"></div>

		<div class="hero-center-section pages">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-12 parallax-fade-top">
						<div class="hero-text" style="font-size: 2em">{{title}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="section padding-top padding-bottom-smaller z-bigger background-grey">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="section drop-shadow rounded">
						<div class="post-box background-white over-hide">
							<img :src="images[0]" alt="" class="blog-home-img"/>
							<div class="padding-in">
								<p class="mt-4" style="color:black; font-weight:bold">{{ title }}</p>
								<p>{{ body}}</p>
								<div class="row mt-4 mb-4">
									<div class="col-md-6">
										<img :src="images[0]" alt="" style="height:200px" class="blog-home-img"/>
									</div>
									<div class="col-md-6">
										<img :src="images[1]" alt="" style="height:200px" class="blog-home-img mt-4 mt-md-0"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    <Footer/>
</div>
</template>

<script>
import Navbar from "./Navbar";
import Footer from "./Footer";
import {newsRef} from "../config/firebase"
export default {
    name: 'newsDetail',
    components: {
    Navbar,
    Footer
  },
data(){
	return{
     body:null,
		title:null,
		images:[]
    }
  },
  watch:{
        '$route': 'fetchData'
    },
    methods:{
        fetchData(){
		return 	newsRef.where('id', '==',this.$route.params.id)
            .get().then(
                querySnapshot =>{
                    querySnapshot.forEach(doc =>{
                        this.body = doc.data().body,
						this.title = doc.data().title,
						this.images =  doc.data().images
                    })
                }
            )
        },
	},

beforeRouteEnter(to, from, next){

		// .where('id', '==', to.params.id)
	return 	newsRef.get()
		.then((querySnapShot)=>{
			querySnapShot.forEach(
				doc=>{
					next(vm=>{
						vm.id = doc.id,
						vm.body = doc.data().body,
						vm.title = doc.data().title,
						vm.images =  doc.data().images

					})
				}
			)
		})
  },

}
</script>

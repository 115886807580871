<template>
    <div>
        <Navbar/>
        <!-- Primary Page Layout================================================== -->

        <div class="section big-55-height over-hide z-bigger" style="height:250px">
            <div class="parallax parallax-top" style="background-image: url('assets/img/image9.jpeg')"></div>
            <div class="dark-over-pages"></div>

            <div class="hero-center-section pages">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 parallax-fade-top">
                            <div class="hero-text">Get in Touch</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="section padding-top z-bigger">
            <div class="container">
                <div class="row justify-content-center padding-bottom-smaller">
                    <div class="col-md-6 col-lg-4">
                        <div class="address">
                            <div class="address-in text-left">
                                <p class="color-black">Phone:</p>
                            </div>
                            <div class="address-in text-right">
                                <p>+353 857 862 720</p>
                            </div>
                        </div>
                        <div class="address">
                            <div class="address-in text-left">
                                <p class="color-black">Email:</p>
                            </div>
                            <div class="address-in text-right">
                                <p >contact@havemyroom.com</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="address">
                            <div class="address-in text-left">
                                <p class="color-black">Address:</p>
                            </div>
                            <div class="address-in text-right">
                                <p>2A Breffni Court Watercourse road Cork Ireland</p>
                            </div>
                        </div>
                        <div class="address">
                            <div class="address-in text-left">
                                <p class="color-black">City:</p>
                            </div>
                            <div class="address-in text-right">
                                <p>Cork Ireland</p>
                            </div>
                        </div>
                    </div>
                    <div class="section clearfix"></div>

                    <div class="col-md-8 text-center mt-5" data-scroll-reveal="enter bottom move 50px over 0.7s after 0.2s">
                        <p class="mb-0"><em>we are available 24/7</em></p>
                        <h2 class="text-opacity">+353 857 862 720</h2>
                        <a href="https://wa.me/+353857862720" target="_blank" class="btn btn-warning" style="background-color: goldenrod">
                            <i class="fa fa-phone-alt"></i> Contact us
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Footer from "./Footer";
import Navbar from "./Navbar";

export default {
    name: 'Contact',
    components: {
        Footer,
        Navbar
    }
}
</script>

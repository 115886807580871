import {HMRInstance} from "../../utils/globalObjects";
import HotelModel from "../../models/hotel_model";
import {CustomResponse} from "../../utils/globalObjects";
import {fetchRoomsInFirestore} from "../../config/geofirex";
import {rakutenHotelsRef} from "../../config/firebase";
import dayjs from "dayjs";
//import firebase from "@/config/firebase";


export default {
  namespaced: true,
  state: {
    hotels: [],
    activeHotel: new HotelModel(),

    rakuten_hotel_list_response: {},
  },
  getters: {
    getHotels: state => state.hotels,
    getActiveHotel: state => state.activeHotel,

    get_hotel_list: (state) => state.rakuten_hotel_list_response,
  },
  mutations: {
    setHotels: (state, payload) => state.hotels = payload,
    setActiveHotel: (state, payload) => state.activeHotel = payload,
    reset(state) {
      state.hotels.length = 0;
      state.activeHotel = new HotelModel();

      state.rakuten_hotel_list_response = {};
    }
  },
  actions: {
    async searchHotelsFromImpala(context, search_params) {
      const response = new CustomResponse();
      response.data = [];
      const latitude = parseFloat(search_params.latitude)
      const longitude = parseFloat(search_params.longitude)
      const radius = 5;   // km
      const start = dayjs(search_params.checkIn).format("YYYY-MM-DD")
      const end = dayjs(search_params.checkOut).format("YYYY-MM-DD")
      const sortBy = 'createdAt:desc';
      let result = [];
      // eslint-disable-next-line no-unused-vars
      let total_result = 0;
      let total_fetched = 0;
      let next_val = true;
      try {
        while (next_val) {
          let tmp_result = (await HMRInstance.get('impala/hotels', {
            params: {
              latitude,
              longitude,
              radius,
              start,
              end,
              sortBy,
              offset: total_fetched
            }
          })).data;
          result = result.concat(tmp_result.data);
          next_val = tmp_result.pagination.next;
          total_result = tmp_result.pagination.total;
          total_fetched += tmp_result.pagination.count;
        }
        if (result.length > 0) {
          response.data = result.map(hotel => {
            const hotel_instance = new HotelModel();
            hotel_instance.FromImpala(hotel);
            return hotel_instance
          });
        }
      } catch (e) {
        console.log(e)
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },

    async searchHotelsFromRakuten({state}, search_params) {
      const response = new CustomResponse();
      response.data = [];
      try {
        const lat = parseFloat(search_params.latitude)
        const lon = parseFloat(search_params.longitude)
        let rooms_in_rakuten_hotels_collection = await fetchRoomsInFirestore(lat, lon, rakutenHotelsRef)

        /*  let rooms_in_rakuten_hotels_collection  = [
            {id: "usg1",
              address : 'Enugu Nigeria',
              amenities: [],
              availabilityEnd : firebase.firestore.Timestamp.fromDate(new Date("2021-08-30")),
              availabilityStart : firebase.firestore.Timestamp.fromDate(new Date("2021-08-24")),
              bookMarkers: [],
              createdAt : firebase.firestore.FieldValue.serverTimestamp(),
              createdBy : 'rakuten',
              geoLocation : { geo:{lat: '40', lon: '50'}},
              hotelPhoneNumber: "09023444555089",
              initialBookingPoint : '',
              isHotDeal : false,
              isVisible : true,
              name : 'ziza hotel',
              originalPrice : 0,
              picturesURL : ["https://property-gallery.rakutentravelxchange.com/000J/0.jpg", "https://property-gallery.rakutentravelxchange.com/000J/1.jpg", "https://property-gallery.rakutentravelxchange.com/000J/2.jpg"],
              price : 0,
              rating : 5,
              hotelDescription : '',
              status : '',
              apiResponse : Object.assign({}, {name:'ziz'}),
              currency : "EUR",
              updatedAt : firebase.firestore.FieldValue.serverTimestamp()
          }]
*/
        //query the rakuten hotel_list API
        if (rooms_in_rakuten_hotels_collection.length > 0) {
          let array_of_hotels_id = rooms_in_rakuten_hotels_collection.map(hotel => hotel.id)
          let source_market = rooms_in_rakuten_hotels_collection[0].apiResponse.country_code
          // let source_market = "SG"

          let checkIn = dayjs(search_params.checkIn).format("YYYY-MM-DD")
          let checkOut = dayjs(search_params.checkOut).format("YYYY-MM-DD")

          let tmp_result = await new Promise((resolve, reject) => {

            var timer = setInterval(async () => {
              try {
                let _result = (await HMRInstance.post('get_list_of_available_hotels', {
                  check_in_date: checkIn,
                  check_out_date: checkOut,
                  adult_count: search_params.adultsCount,
                  children: search_params.childrenList,
                  source_market: source_market,
                  hotel_id_list: array_of_hotels_id
                })).data;

                if (_result.response.status === 'complete') {
                  resolve(_result)
                  clearInterval(timer)
                }

              } catch (e) {
                reject(e.response.data.message)
                clearInterval(timer)
              }

            }, 700)

          });

          const rakuten_hotels_id = tmp_result.response.hotels.map(hotel => hotel.id)
          const filter_hotels = rooms_in_rakuten_hotels_collection.filter(hotel => rakuten_hotels_id.includes(hotel.id))
          response.data = filter_hotels.map(_firestore_hotel => {
            const exists = tmp_result.response.hotels.find(_rakuten_hotel => _firestore_hotel.id === _rakuten_hotel.id);
            const hotel_instance = new HotelModel();
            if (exists) {

              _firestore_hotel.price = Number(exists.rates.packages[0].room_rate) + Number(0.12 * exists.rates.packages[0].room_rate);
              _firestore_hotel.hotelDescription = exists.rates.packages[0].room_details.supplier_description === null ? exists.rates.packages[0].room_details.description : exists.rates.packages[0].room_details.supplier_description

              hotel_instance.FromRakuten(_firestore_hotel);
            }

            return hotel_instance
            // return _firestore_hotel
          })

          let hotel_search_response = tmp_result.response
          delete hotel_search_response.hotels
          delete hotel_search_response.search.hotel_id_list
          state.rakuten_hotel_list_response = hotel_search_response;


        }


      } catch (e) {
        response.set_status(false, e)
      }


      return Promise.resolve(response)
    },

  }
}

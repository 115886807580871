import firebase from '../config/firebase'
import RoomModel from "@/models/room_model";
import {UserModel} from "@/models/user";

class BookingModel {
  constructor(search_params, payment_reference, user = new UserModel(), room = new RoomModel()) {
    this.adultsCount = search_params.adultsCount
    this.bookingAPIResponse = null
    this.bookingStatus = ''
    this.cancellationPolicy = 'N/A'
    this.checkInDate = firebase.firestore.Timestamp.fromDate(new Date(search_params.checkIn))
    this.checkOutDate = firebase.firestore.Timestamp.fromDate(new Date(search_params.checkOut))
    this.childrenList = search_params.childrenList
    this.cost = 0
    this.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    this.createdBy = user.id
    this.customerName = user.data.fullName
    this.customerPhoneNumber = user.data.phone
    this.paymentOption = 'stripe'
    this.paymentReference = payment_reference
    this.paymentStatus = 'pay with card'
    this.propertyDetail = Object.assign({}, room)
    this.roomID = room.id
    this.roomVendor = room.createdBy
    this.roomsCount = 1
    this.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    this.vendorFee = 0
  }


  SaveHaveMyRoomBooking(cost) {

    this.bookingStatus = 'confirmed';
    this.cost = cost
    this.vendorFee = cost - (0.15 * cost);
  }


  SaveRakutenBooking(booking_response, cost) {

    this.bookingAPIResponse = Object.assign({}, booking_response);
    this.bookingStatus = booking_response.status === 'bkg-ip' ? 'in-progress' : booking_response.status === 'bkg-ps' ? 'pending-supplier' : booking_response.status === 'bkg-cf' ? 'confirmed' : 'acquisition-failed';
    this.cancellationPolicy = booking_response.cancellation_policy;
    this.cost = cost
    this.vendorFee = cost - (0.12 * cost);
  }


  SaveImpalaBooking(booking_response, room, cost) {

    this.bookingAPIResponse =  Object.assign({}, booking_response.data);
    this.bookingStatus = booking_response.data.status
    this.cancellationPolicy = room.cancellation_policies;
    this.cost = cost
    this.vendorFee = cost - ((Number.parseFloat(room.sellerCommissionPercentage||'0')/100) * cost )
  }


}

export default BookingModel

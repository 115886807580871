import Room from "../../models/room";
import {CustomResponse} from "../../utils/globalObjects";
import firebase, {roomsRef, storageRef} from "../../config/firebase";
import {fetchRoomsInFirestore} from "../../config/geofirex";

import {HMRInstance} from "../../utils/globalObjects";
import RoomModel from "../../models/room_model";
import HotelModel from "../../models/hotel_model";

export default {
  namespaced: true,
  state: {
    single_room: {},
    initial_state: {},
    rooms_loading: false,
    room_is_empty: false,
    rooms_loaded: [],// list of rooms,
    my_rooms: [],
    hot_deals: [],
    room_is_hot_deal: false,
    rooms_error: false,
    error_message: '',
    search_params: {},

    rakuten_hotel_rooms_response: {},

  },
  getters: {
    get_rooms_loaded: (state) => state.rooms_loaded,
    get_my_rooms:(state) => state.my_rooms,
    get_single_room: (state) => state.single_room,
    get_hotel_deals: (state) => state.hot_deals,
    room_is_loading: (state) => state.rooms_loading,
    room_is_empty: (state) => state.room_is_empty,
    room_is_hot_deal: (state)=> state.room_is_hot_deal,
    room_has_error: (state) => state.rooms_error,
    get_error_message: (state) => state.error_message,
    get_search_params: (state) => state.search_params
  },
  mutations: {
    set_rooms_loaded(state, payload) {
      state.rooms_loaded = payload
    },
    set_my_rooms(state, payload) {
      state.my_rooms = payload
    },
    set_hot_deals(state, payload) {
      state.hot_deals = payload
    },
    set_single_room(state, payload) {
      state.single_room = payload
    },
    set_room_loading(state, payload) {
      state.rooms_loading = payload
    },
    set_room_is_empty(state, payload) {
      state.room_is_empty = payload
    },
    set_room_is_hot_deal(state, payload) {
      state.room_is_hot_deal = payload
    },
    set_search_params(state, payload) {
      state.search_params = payload
    },

    set_room_error(state, payload) {
      state.rooms_error = payload
    },
    set_error_message(state, payload) {
      state.error_message = payload
    },
    reset(state) {
      state.rooms_loaded.length = 0
      state.single_room = {}
      state.initial_state = {}
      state.rooms_error = false
      state.rooms_loading = false
        state.room_is_hot_deal = false
      state.room_is_empty = false
      state.error_message = ''
      state.search_params = {}

      state.hotel_rooms_response = {}
    }
  },
  actions: {
    async add_room({dispatch, rootGetters}, {room, images}) {
      const response = new CustomResponse()
      let uploadImages = await Promise.all(images.map(image => dispatch('uploadImagesToFirebase', image)))

      try {
        room.createdBy = rootGetters['user/get_user'].id
        room.picturesURL = uploadImages
        const tmp_room = new Room({data: room})
            await tmp_room.create()
        response.message = "Room Saved  Successfully"
        return Promise.resolve(response)
      }catch (e) {
        console.log(e.message)
        response.message = e.message
        response.status = false
        return Promise.resolve(response)
      }

    },
    async fetch_my_rooms({commit, rootGetters}){
      commit('set_room_loading', true)
      return   roomsRef.where('createdBy', '==', rootGetters['user/get_user'].id).get()
        .then(
          (querySnapShot)=>{
            let tmp_arr = [];
            querySnapShot.forEach(doc =>{
              let room = doc.data()
              room.id = doc.id
              tmp_arr.push(room)
            })
            commit('set_room_loading', false)
            commit('set_my_rooms', tmp_arr)

          }
        ).catch(err => {
          commit('set_room_loading', false)
          console.log(err.message)
        })
    },
    async fullRoomDetails({state}, room) {
      state.single_room = room
    },
    async searchForHotDeals({commit}) {
      commit('set_room_loading', true)
      commit('set_room_error', false)

      // const today = new Date()
      const tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      const availabilityEnd = new Date(tomorrow).setHours(0, 0, 0, 0)
      const startFrom = firebase.firestore.Timestamp.fromDate(new Date(availabilityEnd))
      return roomsRef.orderBy('availabilityStart', 'desc')
        .where('isVisible', '==', true)
        .where('availabilityStart', '>=', startFrom).get()
        .then((querySnapshot) => {
          const tmp_data = []
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              let room_doc = doc.data();
              room_doc.id = doc.id;
              tmp_data.push({data: room_doc})
            }
          });
          commit('set_room_loading', false)
          commit('set_room_error', false)
          commit('set_hot_deals', tmp_data)

        })
        .catch((error) => {
          commit('set_room_error', true)
          commit('set_error_message', error.message)
          console.log("Error getting documents: ", error);
        });
    },
    // eslint-disable-next-line no-unused-vars
    async searchForRooms({commit, state, dispatch, rootGetters}, search_params) {
      try {
        //update the state to rooms_loading
        commit('set_room_loading', true)
        commit('set_room_error', false)


        let response1 = await Promise.all([
          dispatch('searchRoomsFromFirestore', search_params),
          dispatch('hotel/searchHotelsFromImpala', search_params, {root: true}),
          dispatch('hotel/searchHotelsFromRakuten', search_params, {root: true})
        ])

       // console.log(response1)
        const all_hotels = response1.flatMap(hotel => {
          if (hotel.status) {
            return hotel.data
          } else {
            return []
          }

        })
      //  console.log('hotels', all_hotels)

        if (all_hotels.length === 0) {
          commit('set_room_is_empty', true)
        } else {
          commit('set_room_is_empty', false)
          commit('hotel/setHotels', all_hotels, {root: true})
        }
        commit('set_search_params', search_params)
        commit('set_room_loading', false)
      } catch (e) {
        commit('set_room_error', true)
        commit('set_room_loading', false)
        commit('set_error_message', e.message)
        console.log(e)
      }
      return Promise.resolve(null)
    },
    async searchRoomsFromFirestore(context, search_params) {
      const response = new CustomResponse();
      response.data = [];
      try {
        const lat = parseFloat(search_params.latitude)
        const lon = parseFloat(search_params.longitude)
        const checkIn = firebase.firestore.Timestamp.fromDate(new Date(search_params.checkIn))
        const checkOut = firebase.firestore.Timestamp.fromDate(new Date(search_params.checkOut))

        //query the rooms Collection
        const rooms_in_rooms_collection = await fetchRoomsInFirestore(lat, lon, roomsRef)
        response.data = rooms_in_rooms_collection
          .filter(room => {
            return checkIn >= room.availabilityStart && checkOut <= room.availabilityEnd
          })
          .map(room => {
            let room_instance = new RoomModel();
            room_instance.FromFirestore(room);
            return room_instance
          })
      } catch (e) {
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },
    async searchRoomsFromImpala({commit}, {hotelID, search_params}) {
      commit('set_room_loading', true);
      commit('set_room_error', false);
      const response = new CustomResponse();
      response.data = [];
      const checkIn = new Date(search_params.checkIn);
      const checkInMonth = checkIn.getUTCMonth() + 1;
      const checkOut = new Date(search_params.checkOut);
      const checkOutMonth = checkOut.getUTCMonth() + 1;
      const start = `${checkIn.getFullYear()}-${checkInMonth > 9 ? checkInMonth : '0' + checkInMonth}-${checkIn.getDate()}`
      const end = `${checkOut.getFullYear()}-${checkOutMonth > 9 ? checkOutMonth : '0' + checkOutMonth}-${checkOut.getDate()}`
      let impala_hotel = {}, rooms = [];
      try {
        impala_hotel = (await HMRInstance.get('impala/hotels/get', {
          params: {start, end, hotelID}
        })).data;
        if (Object.entries(impala_hotel).length > 0) {
          const hotel_instance = new HotelModel();
          hotel_instance.FromImpala(impala_hotel);
          impala_hotel.roomTypes.forEach(room => {
            if (room.rates.length > 0) {
              const room_instance = new RoomModel(hotel_instance);
              room_instance.FromImpala(room);
              rooms.push(room_instance)
            }
          });
          commit('set_rooms_loaded', rooms);
        }
      } catch (e) {
        console.log(e);
        commit('set_room_error', true)
        commit('set_error_message', e.message)
        response.set_status(false, e)
      }
      commit('set_room_loading', false)
      return Promise.resolve(response)
    },

    async search_rakuten_hotel_rooms({rootGetters, commit, state}, hotel_id) {
      const response = new CustomResponse();


      try {
        let tmp_result = await new Promise((resolve, reject) => {
          commit('set_room_loading', true);
          commit('set_room_error', false);


            var timer = setInterval(async () => {
              try {
              let _result = (await HMRInstance.post('get_hotel_rooms', {

                check_in_date: rootGetters['hotel/get_hotel_list'].search.check_in_date,
                check_out_date: rootGetters['hotel/get_hotel_list'].search.check_out_date,
                adult_count: rootGetters['hotel/get_hotel_list'].search.adult_count,
                children: rootGetters['hotel/get_hotel_list'].search.children,
                source_market: rootGetters['hotel/get_hotel_list'].search.source_market,
                hotel_id: hotel_id,
                room_count: rootGetters['hotel/get_hotel_list'].search.room_count,
                currency: rootGetters['hotel/get_hotel_list'].search.currency,
                locale: rootGetters['hotel/get_hotel_list'].search.locale,
                session_id: rootGetters['hotel/get_hotel_list'].session_id
              })).data

              if (_result.response.status === 'complete') {
                resolve(_result);
                clearInterval(timer)
              }
              } catch (e) {
                reject(e.response.data.message)
                clearInterval(timer)
                commit('set_room_error', true)
                commit('set_error_message', e.response.data.message)

              }
            }, 700)

        })

        state.hotel_rooms_response = tmp_result.response;
      //  console.log('hotel_rooms', tmp_result.response)

        const rakuten_hotel = rootGetters['hotel/getHotels'].find(hotel => hotel.id === hotel_id)

        response.data = tmp_result.response.hotels[0].rates.packages.map(room => {

          room.package = {...room};
          room.session_id = tmp_result.response.session_id;
          room.hotelDescription = room.room_details.supplier_description === null ? room.room_details.description : room.room_details.supplier_description;
          room.price = room.minimum_selling_rate && room.minimum_selling_rate > room.room_rate ?   Number(room.minimum_selling_rate + (0.12 * room.minimum_selling_rate))   :  Number(room.room_rate + (0.12 * room.room_rate))
          room.check_in_date = tmp_result.response.search.check_in_date;
          room.check_out_date = tmp_result.response.search.check_out_date;
          room.search = tmp_result.response.search;


          const room_instance = new RoomModel(rakuten_hotel)
          room_instance.FromRakuten(room, rakuten_hotel);
          return room_instance
        })

        commit('set_room_loading', false);
        commit('set_rooms_loaded', response.data);
        return Promise.resolve(response)
      } catch (e) {
        commit('set_room_loading', false)
        commit('set_room_error', true)
        commit('set_error_message', e.message)
        response.set_status(false, e)
        return Promise.resolve(response)
      }

    },

    async uploadImagesToFirebase({rootGetters}, image) {

      return new Promise((resolve, reject) => {
        if (image !== null || image !== undefined || image !== '') {
          let user = rootGetters['user/get_user'].id;
          let pictureName = image.name.split(" ").join("")
          let uploadLogo = storageRef.child(`room_photos/${user}/${pictureName}`).put(image);

          uploadLogo.on(`state_changed`, snapshot => {
              let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('progress', progress)
            }, error => {
              console.log(error.message)
            reject(new Error(error.message))
            },
            () => {
              uploadLogo.snapshot.ref.getDownloadURL().then((imageURL) => {
                resolve(imageURL)
              })
            }
          )
        } else {
          reject(new Error('invalid image parameter'))
        }
      })

    },

    reset({commit}) {
      commit('reset')
    }
  }
}

import {firestore} from "../config/firebase";
import HotelModel from "./hotel_model";

class RoomModel{
    constructor(hotel=new HotelModel()) {
        this.address = hotel.address
        this.amenities = []
        this.availabilityEnd = firestore.FieldValue.serverTimestamp()
        this.availabilityStart = firestore.FieldValue.serverTimestamp()
        this.bookMarkers = []
        this.createdAt = hotel.updatedAt
        this.createdBy = hotel.createdBy
        this.geoLocation = hotel.geoLocation
        this.hotelPhoneNumber = hotel.hotelPhoneNumber
        this.id = hotel.id
        this.initialBookingPoint = ''
        this.isHotDeal = false
        this.isVisible = true
        this.name = ''
        this.originalPrice = 0
        this.picturesURL = []
        this.price = 0
        this.rating = hotel.rating
        this.hotelDescription = ''
        this.status = ''
        this.apiResponse = Object.assign({}, hotel)
        this.currency = hotel.currency
        this.updatedAt = hotel.updatedAt
    }

    FromFirestore(room){
        this.address = room['address'];
      this.apiResponse = null
      this.availabilityStart = room['availabilityStart'].toDate();
      this.availabilityEnd = room['availabilityEnd'].toDate();
      this.bookMarkers = room['bookMarkers'];
      this.createdAt = room['createdAt'].toDate();
      this.createdBy = room['createdBy'];
      this.currency = room["currency"];
      this.geoLocation = room['geoLocation'];
      this.hotelName = room['hotelName'];
      this.hotelPhoneNumber = room['hotelPhoneNumber'];
      this.id = room['id'];
      this.initialBookingPoint = room['initialBookingPoint'];
      this.isHotDeal = room['isHotDeal'];
      this.isVisible = room['isVisible'];
      this.name = room['name'];
      this.originalPrice = room['originalPrice']
        this.picturesURL = room['picturesURL'];
      this.price = Number.parseFloat(room['price']);
        this.rating = room['rating'];
        this.hotelDescription = room['roomDescription'];
        this.status = room['status'];
        this.updatedAt = room['updatedAt'].toDate();
    }
    FromImpala(room){
        this.picturesURL = room.images.map(image=>image.url);
        if(room.rates[0]){
            this.rateId = room.rates[0].rateId;
            this.availabilityStart = new Date(room.rates[0].start);
            this.availabilityEnd = new Date(room.rates[0].end);
            this.price = Number.parseFloat(room.rates[0].retailRate.total.amount / 100);
            this.sellerCommissionPercentage = room.rates[0].sellerCommissionPercentage;
            this.cancellation_policies = room.rates[0].cancellationPolicies
        }
        this.adults = room.maxOccupancy;
        this.name = room.name;
        this.hotelDescription = room.description;
    }

  FromRakuten(room, hotel){

      this.picturesURL = hotel.picturesURL;
      this.availabilityStart = new Date(room.check_in_date);
      this.availabilityEnd = new Date(room.check_out_date);

      this.price = room.price;
      this.client_commission = room.client_commission;
      this.name = hotel.name;
      this.hotelDescription = room.hotelDescription ;
      this.search = room.search;
      this.package = room.package;
      this.session_id = room.session_id;

  }

}

export default RoomModel

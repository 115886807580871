import firebase, {messaging} from '../../config/firebase'
import router from '../../router'
// import $ from 'jquery'
import {UserModel} from "../../models/user";

const state = {
  loading: true,
  error: false
}

const getters = {
  has_error: (state) => state.error,
  is_loading: (state) => state.loading
}

const mutations = {
  toggle_loading_stat(state, value) {
    state.loading = value
  },
  toggle_error_state(state, value) {
    state.error = value
  }
}

const actions = {


  async sign_up_with_email_and_password({dispatch}, {userDetails, _password}) {
    return firebase.auth().createUserWithEmailAndPassword(userDetails.email, _password)
        .then(async (userCredential) => {
          // Signed in
          const userDeviceToken = await dispatch('saveMessagingDeviceToken')
          const response = await dispatch('user/add_user', {userCredential, userDetails, userDeviceToken}, {root: true})
          if(response.status){
            dispatch('authenticate_user', userCredential)
          }
          return Promise.resolve(response)

          // ...
        })
        .catch((error) => {
          console.log('Error code', error.code)
          console.log('Error Message', error.message)
          return Promise.resolve({status: false, message: error.message})
        });
  },


  async login_with_email_and_password({commit, dispatch}, {email, password}) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
        .then(async (result) => {
          //  console.log(result.user)
          const response = await dispatch('authenticate_user', result)
          if (response.code === 200) {
            commit('user/set_auth_type', 'email', {root: true})
            commit('toggle_error_state', false)
            return Promise.resolve(response)
          } else return Promise.reject(response)
        }).catch((err) => {
          // dispatch('logout')
          commit('toggle_error_state', true)
          commit('user/reset', null, {root: true})
          return Promise.resolve({message: err.message, code: 403})
        })
  },


  async sign_in_with_google({dispatch}) {
    let userDetails = new UserModel()
// Start a sign in process for an unauthenticated user.
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');


    return firebase.auth()
        .signInWithPopup(provider)
        .then(async (userCredential) => {

          // The signed-in user info.
          const response1 = await dispatch('authenticate_user', userCredential)

          if(response1.code !== 200){

            let userDeviceToken = await dispatch('saveMessagingDeviceToken')

            userCredential.user.phoneNumber === null ? userDetails.phone = '' : userDetails.phone = userCredential.user.phoneNumber

            userDetails.email = userCredential.user.email;
            userDetails.fullName = userCredential.user.displayName
            userDetails.authType = 'google';

            const response = await dispatch('user/add_user', {userCredential, userDetails, userDeviceToken}, {root: true})
            if (response.status) {
              dispatch('authenticate_user', userCredential)
              return Promise.resolve(response)
            }
          }
          else{
            return  Promise.resolve(response1)
          }



        }).catch((error) => {
          // Handle Errors here.
          console.log('Error code', error.code)
          console.log('Error Message', error.message)
          return Promise.resolve({message: error.message, status: false})
        });
  },


  async sign_in_with_apple({dispatch}) {
    let userDetails = new UserModel()
    var provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');

    return firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (userCredential) => {
          // The signed-in user info.
          const response1 = await dispatch('authenticate_user', userCredential)

          if(response1.code !== 200){

            let userDeviceToken = await dispatch('saveMessagingDeviceToken')

            userCredential.user.phoneNumber === null ? userDetails.phone = '' : userDetails.phone = userCredential.user.phoneNumber

            userDetails.email = userCredential.user.email;
            userDetails.fullName = userCredential.user.displayName
            userDetails.authType = 'apple';

            const response = await dispatch('user/add_user', {userCredential, userDetails, userDeviceToken}, {root: true})
            if (response.status) {
              dispatch('authenticate_user', userCredential)
              return Promise.resolve(response)
            }
          }
          else{
            return  Promise.resolve(response1)
          }


        })
        .catch((error) => {
          // Handle Errors here.
          console.log('Error code', error.code)
          console.log('Error Message', error.message)
          console.log('Error Credential', error.credential)
          return Promise.resolve({message: error.message, status: false})
        });
  },


  // Requests permission to show notifications.
  async requestNotificationsPermissions({dispatch}) {
    console.log('Requesting notifications permission...');
    return   firebase.messaging().requestPermission().then(() => {
      // Notification permission granted.
      dispatch('saveMessagingDeviceToken')
    }).catch((error) => {
      console.error('Unable to get permission to notify.', error);
    });
  },


  async saveMessagingDeviceToken({dispatch}) {

    if(messaging){
      return messaging.getToken({vapidKey: process.env.VUE_APP_VAPID_KEY}).then(currentToken => {
        if (currentToken) {
          return Promise.resolve(currentToken)
        } else {
          // Need to request permissions to show notifications.
          dispatch('requestNotificationsPermissions')
        }

      }).catch((error) => {
        console.error('Unable to get messaging token.', error);
        return Promise.resolve([])
      });
    }else{
      return Promise.resolve([])
    }

  },


  async authenticate_user({dispatch}, result) {
    let response = await dispatch('user/init_user', result, {root: true})
    if (response.code !== 200) {
      response = {message: 'Authentication Error', code: 401}
    }
    return Promise.resolve(response)
  },


  reset_password({commit}, _email) {
    return firebase.auth().sendPasswordResetEmail(_email)
        .then(() => {
          commit('toggle_error_state', false)
          return Promise.resolve({message: ` Successfully sent password reset Email to ${_email}`, status: true})
        }).catch((error) => {
          commit('toggle_error_state', true)
          return Promise.resolve({message: error.message, status: false})
        })
  },


  async logout({commit}) {
    //  $('.tooltips').tooltip('hide')
    try {
      await firebase.auth().signOut()
    } catch (e) {
      console.log('Error while logging out:', e.message)
    }
    await commit('user/reset', null, {root: true})
    await commit('room/reset', null, {root: true})
    await commit('booking/reset', null, {root: true})
    localStorage.removeItem('HaveMyRoomWeb')
    if (router.currentRoute.name !== 'Home') {
      return router.push({name: 'Home'})
    }
    else {
      return router.go(0)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

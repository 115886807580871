import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

var ls = new SecureLS({isCompression: false})

/** Modules **/
import auth from './modules/auth'
import user from './modules/user'
import room from './modules/room'
import booking from './modules/booking'
import hotel from "./modules/hotel";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'HaveMyRoomWeb',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    room,
    booking,
    hotel
  }
})

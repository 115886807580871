import methodMixins from "@/utils/mixins/methodMixins";

class RakutenBookingPolicyModel{
  constructor() {

    this.booking_policy_id = '';
    this.penalty_percentage = '';
    this.date_from = '';
    this.date_to = new Date();
    this.remarks = new Date();
    this.check_in_instructions =  null;
    this.hotel_fees =  null;

  }


  BookingPolicy(room){

  this.booking_policy_id = room.booking_policy_id;
  this.penalty_percentage = room.cancellation_policy.cancellation_policies[0].penalty_percentage;
  this.date_from = methodMixins.methods.get_readable_dates(room.cancellation_policy.cancellation_policies[0].date_from) ;
  this.date_to = methodMixins.methods.get_readable_dates( room.cancellation_policy.cancellation_policies[0].date_to);
  this.remarks = room.cancellation_policy.remarks;
    this.check_in_instructions = room.check_in_instructions || null;
  this.hotel_fees = room.hotel_fees || null;
  }

}

export default RakutenBookingPolicyModel

import Vue from 'vue'
import VueRouter from 'vue-router'
 import store from '../store'
import Home from '../components/Home.vue'
import Contact from '../components/Contact.vue'
import Services from '../components/pages/Services.vue'
import Gallery from '../components/pages/Gallery.vue'
import Faq from '../components/Faq.vue'
import Bookings from "../components/pages/Bookings";
import Terms from '../components/pages/Terms.vue'
import Rooms from '../components/rooms/Rooms.vue'
import Roomsgallery from '../components/rooms/Roomsgallery.vue'
import HotDeals from '../components/rooms/HotDeals.vue'
import Blog from '../components/Blog.vue'
import About from '../components/About.vue'
import Login from '../components/authentications/Login.vue'
import Signup from '../components/authentications/Signup.vue'
import Passwordreset from '../components/authentications/Passwordreset.vue'
import Profile from '../components/authentications/Profile.vue'
import post_rooms from '../components/rooms/post_rooms.vue'
import Payment from "../components/pages/Payment";
import SuccessPage from "../components/pages/SuccessPage";
import newsDetail from "../components/newsDetail";
import BookingInfo from "../components/pages/BookingInfo";
Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      component: Home
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/hotdeals',
      name: 'HotDeals',
      component: HotDeals,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/services',
      name: 'services',
      component: Services,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/hotel-search-result',
      name: 'HotelSearchResult',
      component: ()=>import("../components/hotel/HotelSearchResult"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/hotel-info',
      name: 'HotelInfo',
      component: ()=>import("../components/hotel/HotelInfo"),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: Gallery,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/bookings',
      name: 'bookings',
      component: Bookings,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/booking-info',
      name: 'BookingInfo',
      component: BookingInfo,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/rooms',
      name: 'rooms',
      component: Rooms,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/news',
      name: 'blog',
      component: Blog,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/roomsgallery',
      name: 'roomsgallery',
      component: Roomsgallery,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/passwordreset',
      name: 'PasswordReset',
      component: Passwordreset,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/post_rooms',
      name: 'post_rooms',
      component: post_rooms,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/payment',
      name: 'Payment',
      component: Payment,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:id',
      name: 'newsdetail',
      component: newsDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/successfulpayment',
      name: 'PaymentSuccess',
      component: SuccessPage,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0,0);
  let loggedUser =  store.getters['user/is_loggedIn']
 // let loggedUser = sessionStorage.getItem('loggedStatus') ;
 // console.log(loggedUser)
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if(requiresAuth && !loggedUser) next({name: 'login'});
  else  next()
})



export default router

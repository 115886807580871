<template>
    <div>
        <Navbar/>
        <div class="section big-55-height over-hide" style="height:250px">

            <div v-if="room.hasOwnProperty('id')" class="parallax parallax-top" v-bind:style="{ 'background-image': 'url(' + room.picturesURL[0] + ')' }"></div>
            <div v-else class="parallax parallax-top" style="background-image: url('assets/img/image6.jpeg')"></div>
            <div class="dark-over-pages"></div>

            <div class="hero-center-section pages">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 parallax-fade-top">
                            <div class="hero-text">{{room.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section background-dark padding-top-bottom-smaller over-hide">
            <div class="section z-bigger">
                <div class="container">
                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
                            <div class="amenities">
                                <img src="assets/img/icons/1.svg" alt="">
                                <p>no smoking</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
                            <div class="amenities">
                                <img src="assets/img/icons/2.svg" alt="">
                                <p>big beds</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-md-0">
                            <div class="amenities">
                                <img src="assets/img/icons/3.svg" alt="">
                                <p>yacht riding</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
                            <div class="amenities">
                                <img src="assets/img/icons/4.svg" alt="">
                                <p>free drinks</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
                            <div class="amenities">
                                <img src="assets/img/icons/5.svg" alt="">
                                <p>swimming pool</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
                            <div class="amenities">
                                <img src="assets/img/icons/6.svg" alt="">
                                <p>room breakfast</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section padding-top-bottom z-bigger">
            <div class="section z-bigger">
                <div class="container">
                    <div class="row">
                      <template v-if="room.hasOwnProperty('id')">
                        <div class="col-lg-8 mt-4 mt-lg-0" >
                            <div class="section">
                                <div class="customNavigation rooms-sinc-1-2">
                                    <a class="prev-rooms-sync-1"></a>
                                    <a class="next-rooms-sync-1"></a>
                                </div>
                                <div id="rooms-sync1" class="owl-carousel">
                                    <div class="item" v-for="(picture, index) in room.picturesURL" :key="index">
                                        <img class="carousel-img" :src="picture"  />
                                    </div>
                                </div>
                            </div>
                            <div class="section">
                                <div id="rooms-sync2" class="owl-carousel">
                                    <div class="item" v-for="(picsPreview, index) in room.picturesURL" :key="index">
                                        <img class="carousel-img-prev" :src="picsPreview" />
                                    </div>
                                </div>
                            </div>


                            <div class="section pt-5">
                                <h5>{{room.name}}</h5>

                            </div>
                            <div class="section pt-5">
                                <h5>Description</h5>
                                <p class="mt-3" v-html="room.hotelDescription"></p>
                            </div>
                            <div class="section pt-4">
                                <div class="row">
                                    <div class="col-12">
                                        <h5 class="mb-3">Overview</h5>
                                    </div>
                                    <div class="col-lg-12">
                                        <p><strong class="color-black">Hotel Name:</strong> {{room.name}}</p>
                                        <p><strong class="color-black">Address:</strong> {{room.address}}</p>
                                      <template v-if="!isHotDeal">
                                        <p><strong class="color-black">Check In Date:</strong> {{ get_readable_dates(search_params.checkIn)}}</p>
                                        <p><strong class="color-black">Check Out Date:</strong> {{ get_readable_dates(search_params.checkOut)}}</p>
                                      </template>
                                      <p><strong class="color-black">Price per night:</strong> {{ get_currency_symbol(room.currency)||'&euro;' }}<template v-if="isHotDeal">{{get_round_up_price(room.price)}}</template><template v-else>{{get_round_up_price(room.price / numberOfNight)}}</template></p>
                                      <div v-if="room.createdBy === 'rakuten'">
                                        <div v-if="isLoadingPolicy">
                                          <p>fetching booking policy...</p>
                                        </div>
                                        <div v-else-if="Object.keys(policy).length > 0">
                                          <h5>Cancellation Policy</h5>
                                          <p>There will be a penalty of {{policy.penalty_percentage}}%, if you cancel the booking from {{ policy.date_from }}</p>
                                          <p><strong class="color-black">Remarks:</strong><span v-html="policy.remarks"></span></p>
                                        </div>
                                        <div v-else>
                                          <p>Failed to fetch booking policy</p>
                                        </div>
                                      </div>
                                        <button class="mt-1 btn btn-primary" href="javascript:void(0)" @click="updateRoom(room)" :disabled="room.createdBy === 'rakuten' && Object.keys(policy).length < 1">
                                            book now {{ get_currency_symbol(room.currency)||'&euro;' }}{{ get_round_up_price(room.price) }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </template>

                      <template v-else>
                        <div class="col-lg-8 mt-4 mt-lg-0" >
                          <h5>Failed to Load Room</h5>
                        </div>
                      </template>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>

    </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from 'vuex'
import methodMixins from "../../utils/mixins/methodMixins";
import dayjs from "dayjs";

export default {
    name: 'RoomsGallery',
    data(){
        return{

        }
    },
    components: {
        Navbar,
        Footer
    },
    computed:{
        ...mapGetters({
            room: 'room/get_single_room',
            search_params: 'room/get_search_params',
          policy: 'booking/get_post_booking_policy',
          isLoadingPolicy: 'booking/isLoading',
          isHotDeal: 'room/room_is_hot_deal'
        }),
      numberOfNight() {
        const check_in_date = new Date(this.room.availabilityStart)
        const check_out_date = new Date(this.room.availabilityEnd)
        return dayjs(check_out_date).diff(dayjs(check_in_date), 'day');

      },
    },
    mixins: [methodMixins],
    methods:{
        async updateRoom(room){
            await this.$store.commit('room/set_single_room', room)
            await this.$router.push({name: 'Payment'})
        },
        get_readable_date: (timeStamp) => methodMixins.methods.get_readable_datetime(timeStamp),
      get_readable_dates: (_date) => methodMixins.methods.get_readable_dates(_date),

    },
    mounted() {
      this.$store.dispatch('booking/fetch_rakuten_post_booking_policy')
        var sync1 = $("#rooms-sync1");
        var sync2 = $("#rooms-sync2");
        var sync3 =$("#rooms-sync3");
        var sync4 =$("#rooms-sync4");

        sync1.owlCarousel({
            singleItem : true,
            slideSpeed : 400,
            transitionStyle : "goDown",
            pagination:false,
            autoPlay : 6000,
            afterAction : syncPosition
        });
        sync2.owlCarousel({
            items : 5,
            itemsDesktop      : [1199,4],
            itemsDesktopSmall     : [979,3],
            itemsTablet       : [768,3],
            itemsMobile       : [479,2],
            pagination: false,
            responsiveRefreshRate : 100,
            afterInit : function(el){
                el.find(".owl-item").eq(0).addClass("synced");
            }
        });
        sync3.owlCarousel({
            singleItem : true,
            slideSpeed : 400,
            transitionStyle : "goDown",
            pagination:false,
            autoPlay : 6000,
            afterAction : syncPosition1
        });
        sync4.owlCarousel({
            items : 5,
            itemsDesktop      : [1199,4],
            itemsDesktopSmall     : [979,3],
            itemsTablet       : [768,3],
            itemsMobile       : [479,2],
            pagination: false,
            responsiveRefreshRate : 100,
            afterInit : function(el){
                el.find(".owl-item").eq(0).addClass("synced");
            }
        });
        // eslint-disable-next-line no-unused-vars
        function syncPosition(el){
            var current = this.currentItem;
            sync2
                .find(".owl-item")
                .removeClass("synced")
                .eq(current)
                .addClass("synced")
            if(sync2.data("owlCarousel") !== undefined){
                center(current)
            }
        }
        function center(number){
            var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
            var num = number;
            var found = false;
            for(var i in sync2visible){
                if(num === sync2visible[i]){
                    found = true;
                }
            }
            if(found===false){
                if(num>sync2visible[sync2visible.length-1]){
                    sync2.trigger("owl.goTo", num - sync2visible.length+2)
                }else{
                    if(num - 1 === -1){
                        num = 0;
                    }
                    sync2.trigger("owl.goTo", num);
                }
            } else if(num === sync2visible[sync2visible.length-1]){
                sync2.trigger("owl.goTo", sync2visible[1])
            } else if(num === sync2visible[0]){
                sync2.trigger("owl.goTo", num-1)
            }
        }
        // eslint-disable-next-line no-unused-vars
        function syncPosition1(el){
            var current = this.currentItem;
            $("#rooms-sync4")
                .find(".owl-item")
                .removeClass("synced")
                .eq(current)
                .addClass("synced")
            if($("#rooms-sync4").data("owlCarousel") !== undefined){
                center1(current)
            }
        }
        function center1(number){
            var sync4visible = sync4.data("owlCarousel").owl.visibleItems;
            var num = number;
            var found = false;
            for(var i in sync4visible){
                if(num === sync4visible[i]){
                    found = true;
                }
            }
            if(found===false){
                if(num>sync4visible[sync4visible.length-1]){
                    sync2.trigger("owl.goTo", num - sync4visible.length+2)
                }else{
                    if(num - 1 === -1){
                        num = 0;
                    }
                    sync2.trigger("owl.goTo", num);
                }
            } else if(num === sync4visible[sync4visible.length-1]){
                sync2.trigger("owl.goTo", sync4visible[1])
            } else if(num === sync4visible[0]){
                sync2.trigger("owl.goTo", num-1)
            }
        }

        $("#rooms-sync2").on("click", ".owl-item", function(e){
            e.preventDefault();
            var number = $(this).data("owlItem");
            sync1.trigger("owl.goTo",number);
        });
        $("#rooms-sync4").on("click", ".owl-item", function(e){
            e.preventDefault();
            var number = $(this).data("owlItem");
            sync3.trigger("owl.goTo",number);
        });
    }
}
</script>

<style scoped>
.hero-text{
    font-size: 2em;
}
.carousel-img{
    height: 500px!important;
}
.carousel-img-prev{
    height: 100px!important;
}
</style>

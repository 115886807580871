<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide z-bigger" style="height:250px">

       <div id="video-wrap" class="parallax-top">
                <video id="video_background" preload="auto" autoplay loop="loop" muted="muted" poster="assets/img/image7.jpeg">
                    <source src="assets/video/videoE (3).mp4" type="video/mp4">
                </video>
            </div>
        <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">Services</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom-smaller background-dark-2 over-hide">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h5 class="color-grey">A new dimension of luxury.</h5>
            <p class="color-white mt-3 mb-3"><em>our presentation, 0:48 min</em></p>
            <a href="https://vimeo.com/54851233" class="video-button" data-fancybox><i class="fa fa-play"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom over-hide">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 align-self-center">
            <div class="subtitle with-line text-center mb-4">elegant suites</div>
            <h3 class="text-center padding-bottom-small">Unpretentious luxury</h3>
          </div>
          <div class="section clearfix"></div>
          <div class="col-sm-6 col-lg-4">
            <div class="services-box text-center">
              <img src="assets/img/1.svg" alt="">
              <h5 class="mt-2">smoking free</h5>
              <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et.</p>
              <a class="mt-1 btn btn-primary" href="/services">read more</a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 mt-5 mt-sm-0">
            <div class="services-box text-center">
              <img src="assets/img/2.svg" alt="">
              <h5 class="mt-2">king beds</h5>
              <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et.</p>
              <a class="mt-1 btn btn-primary" href="/services">read more</a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 mt-5 mt-lg-0">
            <div class="services-box text-center">
              <img src="assets/img/3.svg" alt="">
              <h5 class="mt-2">Yacht rental</h5>
              <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et.</p>
              <a class="mt-1 btn btn-primary" href="services.html">read more</a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 mt-5">
            <div class="services-box text-center">
              <img src="assets/img/4.svg" alt="">
              <h5 class="mt-2">welcome drink</h5>
              <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et.</p>
              <a class="mt-1 btn btn-primary" href="/services">read more</a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 mt-5">
            <div class="services-box text-center">
              <img src="assets/img/5.svg" alt="">
              <h5 class="mt-2">swimming pool</h5>
              <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et.</p>
              <a class="mt-1 btn btn-primary" href="/services">read more</a>
            </div>
          </div>
          <div class="col-sm-6 col-lg-4 mt-5">
            <div class="services-box text-center">
              <img src="assets/img/6.svg" alt="">
              <h5 class="mt-2">food included</h5>
              <p class="mt-3">Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam, eaque ipsa quae ab
                illo inventore veritatis et.</p>
              <a class="mt-1 btn btn-primary" href="/services">read more</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section background-dark padding-top-bottom-smaller over-hide">
      <div class="section z-bigger">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
              <div class="amenities">
                <img src="assets/img/icons/1.svg" alt="">
                <p>no smoking</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
              <div class="amenities">
                <img src="assets/img/icons/2.svg" alt="">
                <p>big beds</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-md-0">
              <div class="amenities">
                <img src="assets/img/icons/3.svg" alt="">
                <p>yacht riding</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/4.svg" alt="">
                <p>free drinks</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/5.svg" alt="">
                <p>swimming pool</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/6.svg" alt="">
                <p>room breakfast</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom background-grey over-hide">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 align-self-center">
            <div class="subtitle with-line text-center mb-4">Excellent services</div>
            <h3 class="text-center padding-bottom-small">what we offer</h3>
          </div>
          <div class="section clearfix"></div>
        </div>
        <div class="row background-white p-0 m-0">
          <div class="col-xl-6 p-0">
            <div class="img-wrap" id="rev-1">
              <img src="assets/img/image6.jpeg" style="height:300px" alt="">
            </div>
          </div>
          <div class="col-xl-6 p-0 align-self-center">
            <div class="row justify-content-center">
              <div class="col-9 pt-4 pt-xl-0 pb-5 pb-xl-0 text-center">
                <h5 class="">spa salon</h5>
                <p class="mt-3">Sed ut perspiciatis unde omnis, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et.</p>
                <a class="mt-1 btn btn-primary" href="/">explore</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row background-white p-0 m-0">
          <div class="col-xl-6 p-0 align-self-center">
            <div class="row justify-content-center">
              <div class="col-9 pt-4 pt-xl-0 pb-5 pb-xl-0 text-center">
                <h5 class="">restaurant</h5>
                <p class="mt-3">Sed ut perspiciatis unde omnis, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et.</p>
                <a class="mt-1 btn btn-primary" href="/">explore</a>
              </div>
            </div>
          </div>
          <div class="col-xl-6 order-first order-xl-last p-0">
            <div class="img-wrap" id="rev-2">
              <img src="assets/img/ser-2.jpg" style="height:300px" alt="">
            </div>
          </div>
        </div>
        <div class="row background-white p-0 m-0">
          <div class="col-xl-6 p-0">
            <div class="img-wrap" id="rev-3">
              <img src="assets/img/image2.jpeg" style="height:300px" alt="">
            </div>
          </div>
          <div class="col-xl-6 p-0 align-self-center">
            <div class="row justify-content-center">
              <div class="col-9 pt-4 pt-xl-0 pb-5 pb-xl-0 text-center">
                <h5 class="">swimming pool</h5>
                <p class="mt-3">Sed ut perspiciatis unde omnis, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et.</p>
                <a class="mt-1 btn btn-primary" href="/">explore</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row background-white p-0 m-0">
          <div class="col-xl-6 p-0 align-self-center">
            <div class="row justify-content-center">
              <div class="col-9 pt-4 pt-xl-0 pb-5 pb-xl-0 text-center">
                <h5 class="">activities</h5>
                <p class="mt-3">Sed ut perspiciatis unde omnis, totam rem aperiam, eaque ipsa quae ab illo inventore
                  veritatis et.</p>
                <a class="mt-1 btn btn-primary" href="/">explore</a>
              </div>
            </div>
          </div>
          <div class="col-xl-6 order-first order-xl-last p-0">
            <div class="img-wrap" id="rev-4">
              <img src="assets/img/image3.jpeg" style="height:300px" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";

export default {
  name: 'Services',
  components: {
    Navbar,
    Footer
  }
}
</script>

import firebase, { usersRef } from '../config/firebase'

class User {
  constructor ({ id = '', data = {} }) {
    this.id = id
    this.data = Object.assign(new Model(), data)
  }

  create () {
    return usersRef.doc(this.id).set(Object.assign({}, this.data))
  }

  update () {
    this.data.createdAt = firebase.firestore.Timestamp.fromDate(new Date((this.data.createdAt.seconds + this.data.createdAt.nanoseconds * 10 ** -9 ) * 1000))
    this.data.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    return usersRef.doc(this.id).update(Object.assign({}, this.data))
  }
}

function Model () {
  this.authType = ''
  this.createdAt = firebase.firestore.FieldValue.serverTimestamp()
  this.deviceTokens = []
  this.email = ''
  this.fullName = ''
  this.nationality = ''
  this.phone = ''
  this.pictureURL = ''
  this.type = 'user'
  this.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
}

export const UserModel = Model
export default User

<template>
  <div id="app">
      <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #888888;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #DAA520;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #896913;
}
</style>

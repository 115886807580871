<template>
  <div>
    <Navbar/>
    <div class="section">
      <div class="page-content" style="background-image: url('assets/img/image5.jpeg')">
        <div class="wizard-v10-content">
          <div class="wizard-form" >
    <div class="container mt-5 mb-5">
      <div class="row justify-content-center">
          <center>
            <!-- Form -->
            <div class="form align-center col-md-12 col-sm-12" ref="reset">
              <div class="form-group">
                <!-- <label class="text-left col-md-12" style="color:gray" for="email">Email:</label> -->
                <input class="form-control" v-model="email" type="email" placeholder="email">
                <span style="color:red; text-transform:capitalize;"></span>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-block" @click.prevent="resetPassword" type="submit">Reset Password</button>
              </div>

            </div>
            <!-- /Form -->
          </center>
        </div>
      </div>
    </div>
      </div>
    </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {Toast, validateEmail} from "../../utils/mixins/methodMixins";

export default {
  name: 'Passwordreset',
  data(){
    return{
      email: ''
    }
  },
  components: {
    Navbar,
    Footer
  },
  methods:{
    async resetPassword() {
      if (!validateEmail(this.email)) {
        await Toast.fire({
          icon: 'error',
          title: 'Invalid Email Address'
        })
        return false
      }
      const loader = this.$loading.show({container: this.$refs.reset})
      let response = await this.$store.dispatch('auth/reset_password', this.email)
      if (response.status) {
        loader.hide()
        await Toast.fire({
          icon: 'success',
          title: `${response.message}`
        })
      } else {
        loader.hide()
        await Toast.fire({
          icon: 'error',
          title: `${response.message}`
        })
      }
    }
    }



}
</script>

<style scoped>
.login-b {
  background-color: goldenrod;
  height: 130vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.loginbox {
  width: 40%;
  height: 150%;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-top: 100px;
  margin-bottom: 1000px;
  background-color: white;;
  border: 2px solid white;
  vertical-align: middle;
}

input {
  border-radius: 10px;
  width: 320px;
  opacity: 0.5;
  background-color: white;
}

input:hover {
  background-color: white;
}

.form {
  border: none;
  width: 80%;
}

.btn-block {
  background-color: goldenrod;
  width: 320px;
  color: white;
  border-radius: 10px;
}

.btn-block:hover {
  background-color: gray;
}

.page-content {
  width: 100%;
  margin:  0 auto;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}
.wizard-v10-content   {
  background: #fff;
  width: 650px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  margin: 260px 0 160px;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  display: flex;
  display: -webkit-flex;
}
.wizard-v10-content .wizard-form {
  width: 100%;
  border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

</style>

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/messaging'
import 'firebase/storage'

// const production = window.location.hostname === 'havemyroom.com' || window.location.hostname === 'www.havemyroom.com'
    // || window.location.hostname === 'havemyroom.web.app' || window.location.hostname === 'havemyroom.firebaseapp.com'
let firebaseConfig;
// TODO: uncomment to auto switch to config settings
// if(production){
//   firebaseConfig = {
//     apiKey: "AIzaSyBdxCccTH04ROgv3JpVgS9hGUbLDrWtQZw",
//     authDomain: "havemyroom.firebaseapp.com",
//     databaseURL: "https://havemyroom.firebaseio.com",
//     projectId: "havemyroom",
//     storageBucket: "havemyroom.appspot.com",
//     messagingSenderId: "297137725530",
//     appId: "1:297137725530:web:b3fd416795c89c7c20b863",
//     measurementId: "G-20JZ6X722E"
//   }
// }else{
//   firebaseConfig = {
//     apiKey: 'AIzaSyDplsAmPVTSAHx7_HM_6KVDg1xecfEUnoI',
//     authDomain: 'havemyroomstaging.firebaseapp.com',
//     databaseURL: 'https://havemyroomstaging.firebaseio.com',
//     projectId: 'havemyroomstaging',
//     storageBucket: 'havemyroomstaging.appspot.com',
//     messagingSenderId: '20283413943',
//     appId: '1:20283413943:web:252534bde18063c56ec432',
//     measurementId: 'G-2TNK7ZVW6K'
//   }
// }

firebaseConfig = {
  apiKey: 'AIzaSyDplsAmPVTSAHx7_HM_6KVDg1xecfEUnoI',
  authDomain: 'havemyroomstaging.firebaseapp.com',
  databaseURL: 'https://havemyroomstaging.firebaseio.com',
  projectId: 'havemyroomstaging',
  storageBucket: 'havemyroomstaging.appspot.com',
  messagingSenderId: '20283413943',
  appId: '1:20283413943:web:252534bde18063c56ec432',
  measurementId: 'G-2TNK7ZVW6K'
}
// Initialize Firebase


firebase.initializeApp(firebaseConfig)

let _messaging;
export const firestore = firebase.firestore
export const storageRef = firebase.storage().ref()
if(firebase.messaging.isSupported()){
  _messaging = firebase.messaging()
}
export const messaging = _messaging;

export const usersRef = firestore().collection('users')
export const roomsRef = firestore().collection('rooms')
export const bookingsRef = firestore().collection('bookings')
export const rakutenHotelsRef = firestore().collection('rakutenHotels')
export const newsRef = firestore().collection('news')

export default firebase

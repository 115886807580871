<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide">

      <div class="parallax parallax-top" style="background-image: url('assets/img/image7.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">Hot Deals</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="section padding-bottom over-hide mt-5">
      <div class="container">
        <template  v-if="isLoading">
        <div class="col-lg-12 mb-5" data-scroll-reveal="enter bottom move 50px over 0.7s after 0.2s">
          <h5>Fetching Hot Deals......</h5>
        </div>
        </template>

        <template v-else-if="hotDeals.length > 0">
        <div  class="row justify-content-center">
          <div v-for="(room, index) in hotDeals" :key="index" class="col-lg-6 mb-5"
               data-scroll-reveal="enter bottom move 50px over 0.7s after 0.2s">
            <div class="room-box background-grey">
              <div class="room-name">{{ room.data.name }}</div>
              <div class="room-per">
                <i class="fa fa-heart" style="font-size:30px; opacity:0.1"></i>
              </div>
              <div class="img-container img-landscape">
              <img :src="room.data.picturesURL[0]">
              </div>
              <div class="room-box-in">
                <h5 class="">{{ room.data.name }}</h5>
                <p class="mt-3">{{ room.data.roomDescription.split(" ").splice(0, 20).join(" ") }}</p>
                <p>Total stay Price:  &euro;{{
                    room.data.price
                  }}</p>
                <!--                    <p>Date: 21-12-2021</p>-->
                <div class="room-icons mt-4 pt-4">
                  <img src="assets/img/5.svg" alt="">
                  <img src="assets/img/2.svg" alt="">
                  <img src="assets/img/3.svg" alt="">
                  <a href="javascript:void(0)" @click="update_single_room(room)">full info</a>
                </div>
              </div>
            </div>
          </div>

        </div>
        </template>

        <template v-else >
        <div class="col-lg-12 mb-5" data-scroll-reveal="enter bottom move 50px over 0.7s after 0.2s">
          <h5>No Hot Deals Available</h5>
        </div>
        </template>

      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from 'vuex'
import RoomModel from "../../models/room_model";

export default {
  name: 'Favorite',
  components: {
    Navbar,
    Footer
  },
  computed: {
    ...mapGetters({
      hotDeals: 'room/get_hotel_deals',
      isLoading: 'room/room_is_loading',

    })
  },
  methods:{
    async update_single_room(room) {
      const room_in_hot_deal = new RoomModel()
      room_in_hot_deal.FromFirestore(room.data)
      await this.$store.commit('room/set_room_is_hot_deal', true)
      await this.$store.commit('room/set_single_room', room_in_hot_deal)
      await this.$router.push({name: 'roomsgallery'})
    },
  },
  mounted() {
    this.$store.dispatch('room/searchForHotDeals')
  }
}
</script>

<style scoped>
.img-container{
  border: none;
  overflow: hidden;
}
.img-landscape{
  height: 350px;
}
</style>

<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide z-bigger" style="height:250px">
      <div class="parallax parallax-top" style="background-image: url('../../assets/img/image6.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">Post Rooms</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section padding-top z-bigger" ref="post_room">
      <div class="container-fluid card">
        <div class="row justify-content-center padding-bottom-smaller">
          <div class="col-12 col-md-6 card-body" style="border-bottom: 1px solid rgba(0,0,0,0.1)">
            <div class="subtitle with-line text-center mb-4">Post a Room</div>
            <br>
            <div class="row mb-4" style="">
              <div class="col-4" v-for="(image, index) in imagesPreview" v-bind:key="index">
                <div class="img-wrap">
                  <img class="preview" :src="image" style="width: 200px; height: 200px;" />
                  <span class="delete-img" @click.prevent="cancelImage(index)">x</span>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-5 ajax-form mb-5 text-center">
                <input type="file" id="input" name="" accept="image/*" style="display:none" @change="loadFile"
                       ref="fileInput" multiple>
                <button style="background-color: goldenrod; width: 80%;" @click="$refs.fileInput.click()"><i
                  class="fas fa-upload"></i> Upload images
                </button>
              </div>
            </div>
            <div class="col-md-12 ajax-form">
              <label for="">Hotel Name</label>
              <input class="text-left" name="name" v-model="room.name" type="text" placeholder="" autocomplete="off"
                     required>
            </div>
            <div class="col-md-12 mt-4 ajax-form">
              <label for="">Hotel Phone</label>
              <input class="text-left" name="number" v-model="room.hotelPhoneNumber" type="number" autocomplete="off"
                     required>
            </div>
            <div class="col-md-12 mt-4 ajax-form">
              <label for="">Hotel Address</label>
              <vue-google-autocomplete
                ref="address"
                id="map"
                class="text-left"
                placeholder=""
                v-on:placechanged="getAddressData"
              >
              </vue-google-autocomplete>
            </div>
            <div class="col-md-12 mt-4 ajax-form">
              <label for="">Hotel Description</label>
              <textarea class="text-left" name="room" v-model="room.roomDescription" id="" cols="10" rows="40"
                        required></textarea>
            </div>
            <div class="col-md-12 mt-4 ajax-form">
              <label for="">Cost Per Night(EUR)</label>
              <input class="text-left" name="cost" v-model.number="room.price" type="number" autocomplete="off" required>
            </div>
            <div class="col-md-12 mt-4 ajax-form">
              <label for="">Original Cost Per Night(EUR)</label>
              <input class="text-left" name="origin" v-model.number="room.originalPrice" type="number" autocomplete="off"
                     required>
            </div>
            <div class="col-md-12 mt-4">
              <div class="ajax-form">
                <label for="">Available From</label>
                <input class="text-left" v-model="room.availabilityStart" type="date" autocomplete="off"
                       id="start-date-1" name="start" data-date-format="DD, MM d" required>
              </div>
            </div>
            <br>
            <div class="col-md-12 mt-4" style="margin-top:20px">
              <div class="ajax-form">
                <label for="">Available To</label>
                <input class="text-left" v-model="room.availabilityEnd" type="date" autocomplete="off" id="end-date-1"
                       name="end" data-date-format="DD, MM d" required>
              </div>
            </div>
            <div class="col-md-12 mt-4 ajax-form">
              <label for="">
                Initial booking place(for resales only): E.g Expedia, Booking.com, etc
              </label>
              <input class="text-left" name="number" v-model="room.initialBookingPoint" type="text" autocomplete="off"
                     required>
            </div>
            <div class="section clearfix mt-5"></div>
            <div class="col-md-12 mt-3 ajax-form text-center">
              <button class="send_message" @click.prevent="submit" type="submit" id="send"
                      style="background-color:goldenrod; border-radius:30px" data-lang="en" :disabled="loading">
                   submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Footer from "../Footer";
import Navbar from "../Navbar";
import {Toast} from "../../utils/mixins/methodMixins";
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import {geo} from "../../config/geofirex";
import {Room_Model} from "../../models/room";

export default {
  name: 'post_rooms',
  components: {
    Footer,
    Navbar,
    VueGoogleAutocomplete
  },
  data() {
    return {
      images: [],
      imagesPreview: [],
      room: new Room_Model(),
      address: '',
      longitude: '',
      latitude: '',
      loading: false
    }
  },
  mounted() {
    $("#owl-sep-2").owlCarousel({
      navigation: false,
      pagination: false,
      slideSpeed: 500,
      paginationSpeed: 500,
      items: 7,
      itemsDesktop: [1000, 4],
      itemsDesktopSmall: [900, 3],
      itemsTablet: [600, 2],
      itemsMobile: false,
      autoPlay: 5000
    });
  },
  methods: {
    loadFile(event) {
      this.images = [ ...this.images, ...event.target.files];
      for(let file of event.target.files){
          const reader = new FileReader();
          reader.onload = () => {
              this.imagesPreview = [...this.imagesPreview, reader.result];
          };
          reader.readAsDataURL(file);
      }
    },
    cancelImage(index) {
      this.imagesPreview.splice(index, 1);
    },
    getAddressData: function (addressData) {
      this.room.address = addressData.route + " " + addressData.locality + ", " + addressData.country;

      this.latitude = addressData.latitude
      this.longitude = addressData.longitude

    },
    async submit() {
      this.loading = true;
      if (!this.validateForm()) {
        this.loading = false;
        return false
      }
      const loader = this.$loading.show({
        container: this.$refs.post_room,
        loader: 'bars',
        color: '#000',
        width: 150,
        height: 100
      })
      const geolocation = geo.point(this.latitude, this.longitude);
      this.room.geoLocation = geolocation;

      let room = this.room;
      let images = this.images

      let response = await this.$store.dispatch('room/add_room', {room, images})
      if(response.status){
        loader.hide()
        this.loading = false;
        this.room = new Room_Model();
        this.images.length = 0;
        this.imagesPreview.length = 0;
       await Toast.fire({
          icon: 'success',
          title: `${response.message}`
        })
      }
      else{
        loader.hide()
        this.loading = false;
       await Toast.fire({
          icon: 'error',
          title: `${response.message}`
        })
      }
    },

    validateForm() {
      if (!this.room.availabilityEnd || !this.room.availabilityStart ||
        !this.room.address || !this.room.initialBookingPoint ||
        !this.room.price || !this.room.originalPrice ||
        !this.room.roomDescription || !this.room.hotelPhoneNumber ||
      !this.room.name ) {
        Toast.fire({
          icon: 'info',
          title: 'Please complete all field before submitting'
        })
        return false
      }
      else if(new Date(this.room.availabilityEnd).getTime() < new Date(this.room.availabilityStart).getTime()){
        Toast.fire({
          icon: 'info',
          title: 'Date Validation Error',
          text: 'Room availability End should be greater than Room availablity Start'
        })
        return false
      }
      else if(this.images.length === 0){
        Toast.fire({
          icon: 'info',
          title: 'Please Upload a minimum of one image of the hotel room'
        })
        return false
      }
      return true
    },
  },

}
</script>

<style scoped>
#flight-datepicker input::-webkit-input-placeholder,
#flight-datepicker-1 input::-webkit-input-placeholder {
  color: #999999;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

#flight-datepicker input:-moz-placeholder,
#flight-datepicker-1 input:-moz-placeholder {
  color: #999999;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

#flight-datepicker input:-ms-input-placeholder,
#flight-datepicker-1 input:-ms-input-placeholder {
  color: #999999;
  font-size: 12px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.img-wrap {
  position: relative;
  width: 200px
}

.img-wrap span.delete-img {
  color: white;
  background-color: rgb(17, 16, 16);
  padding: 5px;
  font-weight: 900;
  font-size: 14px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.img-wrap span.delete-img:hover {
  cursor: pointer;
}



</style>

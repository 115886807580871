import {bookingsRef} from '../../config/firebase'
import {CustomResponse} from "../../utils/globalObjects";
import {HMRInstance} from "../../utils/globalObjects";
import RakutenBookingPolicyModel from "../../models/rakuten_booking_policy";
import BookingModel from "../../models/booking";

export default {
  namespaced: true,
  state: {
    booking_history: [],
    booking_policy: {},
    loading: false,
  },
  getters: {
    get_booking_history: (state) => state.booking_history,
    get_post_booking_policy: (state) => state.booking_policy,
    isLoading: (state) => state.loading,

  },
  mutations: {
    set_booking_history(state, payload) {
      state.booking_history = payload
    },
    set_loading(state, value) {
      state.loading = value
    },

    reset(state) {
      //  state.booking_history = []
      state.loading = false
      state.booking_policy = {}
    }
  },
  actions: {
    async fetch_booking_history({commit, rootGetters}) {
      commit('set_loading', true)
      return bookingsRef.where('createdBy', '==', rootGetters['user/get_user'].id).get()
        .then((querySnapshot) => {
          let booking_array = []
          querySnapshot.forEach((doc) => {
            booking_array.push({id: doc.id, data: doc.data()})
          });
          commit('set_loading', false)
          console.log(booking_array)
          commit('set_booking_history', booking_array)
        })
        .catch((error) => {
          commit('set_loading', false)
          console.log("Error getting documents: ", error.message);
        });
    },


    async save_room_to_booking({rootGetters, dispatch}, {paymentRef, cost, rakuten_booking}) {
      const response = new CustomResponse()
      const room = rootGetters["room/get_single_room"];
      const search_params = rootGetters["room/get_search_params"];
      const user = rootGetters["user/get_user"];
      const bookingInstance = new BookingModel(search_params, paymentRef, user, room);


      try {
        if (room.createdBy === 'impala') {
          let _response = await dispatch('createImpalaBooking');
          if (_response.status) {
            bookingInstance.SaveImpalaBooking(_response, room, cost)
          } else {
            throw new Error("Unable to book this room:" + _response.message)
          }
        } else if (room.createdBy === 'havemyroom') {
          bookingInstance.SaveHaveMyRoomBooking(cost)
        } else if (room.createdBy === 'rakuten') {
          bookingInstance.SaveRakutenBooking(rakuten_booking, cost)
        }

        await bookingsRef.doc().set(Object.assign({}, bookingInstance));
        response.message = "Successfully saved Booking to firestore"
      } catch (e) {
        console.log(e.message)
        response.message = 'Failed to save booking to firestore '
        response.status = false
      }
      return Promise.resolve(response)

    },
    async createImpalaBooking({rootGetters}) {
      const response = new CustomResponse();
      const search_params = rootGetters["room/get_search_params"];
      const user = rootGetters["user/get_user"];
      const room = rootGetters["room/get_single_room"];
      try {
        const checkIn = new Date(search_params.checkIn);
        const checkInMonth = checkIn.getUTCMonth() + 1;
        const checkOut = new Date(search_params.checkOut);
        const checkOutMonth = checkOut.getUTCMonth() + 1;
        const start = `${checkIn.getFullYear()}-${checkInMonth > 9 ? checkInMonth : '0' + checkInMonth}-${checkIn.getDate()}`
        const end = `${checkOut.getFullYear()}-${checkOutMonth > 9 ? checkOutMonth : '0' + checkOutMonth}-${checkOut.getDate()}`;

        response.data = (await HMRInstance.post('impala/hotels/book', {
          start, end,
          firstName: user.data.fullName, lastName: '',
          email: user.data.email,
          rateId: room.rateId,
          number_of_adults: room.adults
        })).data;
      } catch (e) {
        response.set_status(false, e)
      }
      return Promise.resolve(response)
    },


    async fetch_rakuten_post_booking_policy({rootGetters, state, commit}) {
      const response = new CustomResponse();
      if (rootGetters['room/get_single_room'].createdBy === 'rakuten') {


        commit('set_loading', true)
        try {
          let search = rootGetters["room/get_single_room"].search;
          let hotel_package = rootGetters['room/get_single_room'].package
          let session_id = rootGetters['room/get_single_room'].session_id
          let _result = (await HMRInstance.post('post_booking_policy', {
            search,
            hotel_package,
            session_id
          })).data


          commit('set_loading', false)
          const book_policy_instance = new RakutenBookingPolicyModel()
          book_policy_instance.BookingPolicy(_result.response)

          response.data = book_policy_instance;
          state.booking_policy = book_policy_instance;
         // console.log('booking policy', book_policy_instance)
          return Promise.resolve(response)
        } catch (e) {
          commit('set_loading', false)
          response.set_status(false, e.response.data.message)
          return Promise.reject(response)
        }

      } else state.booking_policy = {};
    },


    async rakuten_hotel_reservation({state, dispatch, rootGetters}, {
      client_reference,
      room_lead_guests,
      contact_person,
      paymentRef,
      cost
    }) {
      const response = new CustomResponse();

      let promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            let _result = (await HMRInstance.post('create_hotel_reservation', {
              booking_policy_id: state.booking_policy.booking_policy_id,
              client_reference,
              room_lead_guests: [room_lead_guests],
              contact_person,
              session_id: rootGetters['room/get_single_room'].session_id
            })).data

           // console.log('reservation instance', _result.response)
            response.data = _result.response
            resolve(response)
          } catch (e) {
            response.set_status(false, e.response.data.message)
            reject(response)
          }

        }, 0)
      })

      try {
        let reserve_hotel = await promise;
        let book_hotel = await dispatch('bookHotelInRakuten', {reservation: reserve_hotel.data})
        let booking_status = await dispatch('fetch_booking_status', {booking: book_hotel.data})
        let save_booking_to_firestore = await dispatch('save_room_to_booking', {
          paymentRef,
          cost,
          rakuten_booking: booking_status.data
        })
        return Promise.resolve(save_booking_to_firestore)
      } catch (e) {
        console.log(e)
        return Promise.resolve(e)
      }

    },

    async bookHotelInRakuten(context, {reservation}) {
      const response = new CustomResponse();

      let promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            let _result = (await HMRInstance.post('book_hotel', {
              booking_id: reservation.booking_id,
              session_id: reservation.session_id
            })).data

            response.data = _result.response
          //  console.log('booking successfull', _result.response)
            resolve(response)
          } catch (e) {
            console.log(e.response.data)
            response.set_status(false, e.response.data.message)
            reject(response)
          }

        }, 0)

      })

      return Promise.resolve(promise)
    },


    async fetch_booking_status(context, {booking}) {
      const response = new CustomResponse();

      let promise = new Promise((resolve, reject) => {
        setTimeout(async () => {

          try {
            let _result = (await HMRInstance.post('get_booking_status', {
              booking_id: booking.booking_id,
              session_id: booking.session_id
            })).data

            let status = _result.response.status === 'bkg-ip' ? 'Your booking is in progress' : _result.response.status === 'bkg-ps' ? ' your booking is in pending-supplier' : _result.response.status === 'bkg-cf' ? 'Your booking has being successfully confirmed' : 'booking failed please contact our customer care for more details';
            response.data = _result.response;
          //  console.log('booking status', _result.response);

            response.message = status
            resolve(response)

          } catch (e) {
            response.set_status(false, e.response.data.message)
            reject(response)
          }


        }, 10000)

      })
      return Promise.resolve(promise)

    },


    async cancel_booking_in_rakuten(context, booking) {
      const response = new CustomResponse();
      let promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            let _result = (await HMRInstance.post('cancel_booking', {
              booking_id: booking.booking_id,
              session_id: booking.session_id
            })).data
            response.data = _result.response

          //  console.log('canceled booking response', _result.response)
            resolve(response)
          } catch (e) {
            response.set_status(false, e.response.data.message)
            reject(response)
          }
        }, 0)
      })
      return Promise.resolve(promise)

    },


    async reset({commit}) {
      commit('reset')

    }
  }
}

<template>
  <div>
    <NavT/>
    <div class="section padding-top-bottom z-bigger" style="margin-top:50px">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Payment Succesful</h1>
            <p>Booking was successful</p>

            <div class="text-center">
                <a href="/" class="btn btn-primary">Home Page</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import NavT from "../NavT";
import Footer from "../Footer";

export default {
  name: "SuccessPage",
  components: {
    NavT,
    Footer
  }
}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./NavT.vue?vue&type=template&id=22166bc4&scoped=true&"
import script from "./NavT.vue?vue&type=script&lang=js&"
export * from "./NavT.vue?vue&type=script&lang=js&"
import style0 from "./NavT.vue?vue&type=style&index=0&id=22166bc4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22166bc4",
  null
  
)

export default component.exports
<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide" style="height:250px">

      <div v-if="room.data.hasOwnProperty('bookingStatus')" class="parallax parallax-top" v-bind:style="{ 'background-image': 'url(' + room.data.propertyDetail.picturesURL[0] + ')' }"></div>
      <div v-else class="parallax parallax-top" style="background-image: url('assets/img/image6.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">{{room.data.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section background-dark padding-top-bottom-smaller over-hide">
      <div class="section z-bigger">
        <div class="container">
          <div class="row">
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
              <div class="amenities">
                <img src="assets/img/icons/1.svg" alt="">
                <p>no smoking</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center">
              <div class="amenities">
                <img src="assets/img/icons/2.svg" alt="">
                <p>big beds</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-md-0">
              <div class="amenities">
                <img src="assets/img/icons/3.svg" alt="">
                <p>yacht riding</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/4.svg" alt="">
                <p>free drinks</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/5.svg" alt="">
                <p>swimming pool</p>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-2 text-center mt-4 mt-lg-0">
              <div class="amenities">
                <img src="assets/img/icons/6.svg" alt="">
                <p>room breakfast</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom z-bigger">
      <div class="section z-bigger">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 mt-4 mt-lg-0" v-if="room.data.hasOwnProperty('bookingStatus')">
              <div class="section">
                <div class="customNavigation rooms-sinc-1-2">
                  <a class="prev-rooms-sync-1"></a>
                  <a class="next-rooms-sync-1"></a>
                </div>
                <div id="rooms-sync1" class="owl-carousel">
                  <div class="item" v-for="(picture, index) in room.data.propertyDetail.picturesURL" :key="index">
                    <img class="carousel-img" :src="picture"  />
                  </div>
                </div>
              </div>
              <div class="section">
                <div id="rooms-sync2" class="owl-carousel">
                  <div class="item" v-for="(picsPreview, index) in room.data.propertyDetail.picturesURL" :key="index">
                    <img class="carousel-img-prev" :src="picsPreview" />
                  </div>
                </div>
              </div>


              <div class="section pt-5">
                <h5>{{room.data.name}}</h5>

              </div>
              <div class="section pt-5">
                <h5>Description</h5>
                <p class="mt-3" v-html="room.hotelDescription"></p>
              </div>
              <div class="section pt-4">
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-3">Overview</h5>
                  </div>
                  <div class="col-lg-12">
                    <p><strong class="color-black" v-if="room.data.propertyDetail.hotelName">Hotel Name:</strong> {{room.data.propertyDetail.hotelName}}</p>
                    <p><strong class="color-black">Room Name:</strong> {{room.data.propertyDetail.name}}</p>
                    <p><strong class="color-black">Address:</strong> {{room.data.propertyDetail.address}}</p>
                    <p><strong class="color-black">Price :</strong> {{ get_currency_symbol(room.data.propertyDetail.currency)||'&euro;' }}{{room.data.propertyDetail.price}} per night</p>
                    <p><strong class="color-black">Date Booked:</strong> {{ get_readable_date(room.data.createdAt)}}</p>
                    <p><strong class="color-black">Payment Status:</strong> {{ room.data.paymentStatus}}</p>
                    <p><strong class="color-black">Check In - Check Out Date:</strong>{{get_readable_date(room.data.checkInDate)}} - {{get_readable_date(room.data.checkOutDate)}}</p>
                    <p><strong class="color-black">Guests:</strong> {{ room.data.roomCount}} Room, {{room.data.adultsCount}} Adult, {{room.data.childrenList.length }} Children</p>
                    <p><strong class="color-black">Description:</strong> {{ room.data.propertyDetail.roomDescription}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 mt-4 mt-lg-0" v-else>
              <div class="section">
                <div class="customNavigation rooms-sinc-1-2">
                  <a class="prev-rooms-sync-1"></a>
                  <a class="next-rooms-sync-1"></a>
                </div>
                <div id="rooms-sync3" class="owl-carousel">
                  <div class="item">
                    <img src="assets/img/image6.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image0.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image3 (1).jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image5.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image6.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image0.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image5.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image3 (1).jpeg" alt="">
                  </div>
                </div>
              </div>
              <div class="section">
                <div id="rooms-sync4" class="owl-carousel">
                  <div class="item">
                    <img src="assets/img/image6.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image5.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image0.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image3 (1).jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image6.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image5.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image0.jpeg" alt="">
                  </div>
                  <div class="item">
                    <img src="assets/img/image3 (1).jpeg" alt="">
                  </div>
                </div>
              </div>
              <div class="section pt-5">
                <h5>discription</h5>
                <p class="mt-3">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                  velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur.</p>
              </div>
              <div class="section pt-4">
                <div class="row">
                  <div class="col-12">
                    <h5 class="mb-3">Overview</h5>
                  </div>
                  <div class="col-lg-6">
                    <p><strong class="color-black">Room size:</strong> 47 - 54 sq m</p>
                    <p><strong class="color-black">Occupancy:</strong> Up to 4 adulds</p>
                    <p><strong class="color-black">View:</strong> Sea view</p>
                    <p><strong class="color-black">Smoking:</strong> No smoking</p>
                  </div>
                  <div class="col-lg-6">
                    <p><strong class="color-black">Bed size:</strong> King and regular</p>
                    <p><strong class="color-black">Location:</strong> Big room 2nd floor</p>
                    <p><strong class="color-black">Room service:</strong> Yes</p>
                    <p><strong class="color-black">Swimming pool:</strong> Yes</p>
                  </div>
                </div>
              </div>
              <div class="section pt-4">
                <h5>Features</h5>
                <p class="mt-3">Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                  velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
                  voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam,
                  nisi ut aliquid ex ea commodi consequatur.</p>
              </div>
            </div>
            <div class="col-lg-4 order-first order-lg-last">
              <div class="section p-4">
                <div class="row">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>

  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from 'vuex'
import methodMixins from "../../utils/mixins/methodMixins";
export default {
  name: 'BookingInfo',
  data(){
    return{

    }
  },
  components: {
    Navbar,
    Footer,
  },
  computed:{
    ...mapGetters({
      room: 'room/get_single_room'
    })
  },
  mixins: [methodMixins],
  methods:{
    get_readable_date: (timeStamp) => methodMixins.methods.get_shorthand_dates(timeStamp),

  },
  mounted() {
    var sync1 = $("#rooms-sync1");
    var sync2 = $("#rooms-sync2");
    var sync3 =$("#rooms-sync3");
    var sync4 =$("#rooms-sync4");

    sync1.owlCarousel({
      singleItem : true,
      slideSpeed : 400,
      transitionStyle : "goDown",
      pagination:false,
      autoPlay : 6000,
      afterAction : syncPosition
    });
    sync2.owlCarousel({
      items : 5,
      itemsDesktop      : [1199,4],
      itemsDesktopSmall     : [979,3],
      itemsTablet       : [768,3],
      itemsMobile       : [479,2],
      pagination: false,
      responsiveRefreshRate : 100,
      afterInit : function(el){
        el.find(".owl-item").eq(0).addClass("synced");
      }
    });
    sync3.owlCarousel({
      singleItem : true,
      slideSpeed : 400,
      transitionStyle : "goDown",
      pagination:false,
      autoPlay : 6000,
      afterAction : syncPosition1
    });
    sync4.owlCarousel({
      items : 5,
      itemsDesktop      : [1199,4],
      itemsDesktopSmall     : [979,3],
      itemsTablet       : [768,3],
      itemsMobile       : [479,2],
      pagination: false,
      responsiveRefreshRate : 100,
      afterInit : function(el){
        el.find(".owl-item").eq(0).addClass("synced");
      }
    });
    // eslint-disable-next-line no-unused-vars
    function syncPosition(el){
      var current = this.currentItem;
      sync2
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced")
      if(sync2.data("owlCarousel") !== undefined){
        center(current)
      }
    }
    function center(number){
      var sync2visible = sync2.data("owlCarousel").owl.visibleItems;
      var num = number;
      var found = false;
      for(var i in sync2visible){
        if(num === sync2visible[i]){
          found = true;
        }
      }
      if(found===false){
        if(num>sync2visible[sync2visible.length-1]){
          sync2.trigger("owl.goTo", num - sync2visible.length+2)
        }else{
          if(num - 1 === -1){
            num = 0;
          }
          sync2.trigger("owl.goTo", num);
        }
      } else if(num === sync2visible[sync2visible.length-1]){
        sync2.trigger("owl.goTo", sync2visible[1])
      } else if(num === sync2visible[0]){
        sync2.trigger("owl.goTo", num-1)
      }
    }
    // eslint-disable-next-line no-unused-vars
    function syncPosition1(el){
      var current = this.currentItem;
      $("#rooms-sync4")
        .find(".owl-item")
        .removeClass("synced")
        .eq(current)
        .addClass("synced")
      if($("#rooms-sync4").data("owlCarousel") !== undefined){
        center1(current)
      }
    }
    function center1(number){
      var sync4visible = sync4.data("owlCarousel").owl.visibleItems;
      var num = number;
      var found = false;
      for(var i in sync4visible){
        if(num === sync4visible[i]){
          found = true;
        }
      }
      if(found===false){
        if(num>sync4visible[sync4visible.length-1]){
          sync2.trigger("owl.goTo", num - sync4visible.length+2)
        }else{
          if(num - 1 === -1){
            num = 0;
          }
          sync2.trigger("owl.goTo", num);
        }
      } else if(num === sync4visible[sync4visible.length-1]){
        sync2.trigger("owl.goTo", sync4visible[1])
      } else if(num === sync4visible[0]){
        sync2.trigger("owl.goTo", num-1)
      }
    }

    $("#rooms-sync2").on("click", ".owl-item", function(e){
      e.preventDefault();
      var number = $(this).data("owlItem");
      sync1.trigger("owl.goTo",number);
    });
    $("#rooms-sync4").on("click", ".owl-item", function(e){
      e.preventDefault();
      var number = $(this).data("owlItem");
      sync3.trigger("owl.goTo",number);
    });
  }
}
</script>

<style scoped>
.hero-text{
  font-size: 2em;
}
.carousel-img{
  height: 500px!important;
}
.carousel-img-prev{
  height: 100px!important;
}
</style>

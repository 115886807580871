<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide" style="height:250px">

      <div class="parallax parallax-top" style="background-image: url('assets/img/image7.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">My Bookings</div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="section padding-top-bottom over-hide background-grey">
      <div class="container">
        <div class="row">
          <template v-if="isLoading">
            <div class="vld-parent">
              <h5>Loading booking history please wait......</h5>
            </div>
          </template>

          <template v-else-if="bookings.length > 0">
            <div class="col-md-6 mb-5" v-for="(booking, index) in bookings" :key="index">
              <div class="room-box background-white">
                <div class="booking-date">
                  <span>Booked On {{get_readable_date(booking.data.createdAt)}}</span>
                </div>
                <div class="checkin-date">
                  <span>Check In: {{get_readable_date(booking.data.checkInDate)}} - {{get_readable_date(booking.data.checkOutDate)}}</span>
                </div>
                <img :src="booking.data.propertyDetail.picturesURL[0]" >
                <div class="room-box-in">
                  <h5 style="font-size: 1.1em;">{{booking.data.propertyDetail.name}}</h5>
                  <p class="mt-3" v-if="booking.data.propertyDetail.roomDescription">{{booking.data.propertyDetail.roomDescription.substr(0, 200)}}</p>

                  <div class="room-icons mt-4 pt-4">
                    <img src="assets/img/5.svg" alt="">
                    <img src="assets/img/2.svg" alt="">
                    <img src="assets/img/3.svg" alt="">
                    <img src="assets/img/1.svg" alt="">
                    <a href="javascript:void(0)" @click="update_single_room(booking)">full Details</a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="mt-5" style="font-size:25px">You haven't made any booking yet</p>
          </template>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from "vuex";
import methodMixins from "../../utils/mixins/methodMixins";

export default {
  name: 'Bookings',
  components: {
    Navbar,
    Footer
  },
  methods:{
    async update_single_room(booking) {
      await this.$store.commit('room/set_single_room', booking)
      await this.$router.push({name: 'BookingInfo'})
    },
    get_readable_date: (timeStamp) => methodMixins.methods.get_shorthand_dates(timeStamp),
  },
  computed: {
    ...mapGetters({
      isLoading: 'booking/isLoading',
      bookings: 'booking/get_booking_history'
    })
  },
  mounted() {
    this.$store.dispatch('booking/fetch_booking_history')
  }
}
</script>

<style scoped>
.booking-date{
  position: absolute;
  top: 20px;
  right: 20px;
  color: #000000;
  padding: 3px;
  background-color: #fff;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 2px;
  z-index: 3;
}

.checkin-date{
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 155px;
  left: 0;
  color: #000000;
  padding: 3px;
  background-color: #fff;
  font-weight: 400;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 2px;
  z-index: 3;
}

</style>

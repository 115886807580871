<template>
    <div>
        <Navbar/>
        <div class="section big-55-height over-hide z-bigger" style="height:250px">

            <div id="video-wrap" class="parallax-top">
                <video id="video_background" preload="auto" autoplay loop="loop" muted="muted" poster="assets/img/images4.jpeg">
                    <source src="assets/video/videoE (1).mp4" type="video/mp4">
                </video>
            </div>
            <div class="dark-over-pages"></div>
            <div class="hero-center-section pages">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 parallax-fade-top">
                            <div class="hero-text">My Rooms</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section padding-top-bottom over-hide background-grey">
            <div class="container">
                <div class="row">
                  <template v-if="isLoading">
                    <div class="col-12 text-center">
                      <h4 class="text-muted">Fetching my Rooms </h4>
                    </div>
                  </template>
                    <template v-else-if="rooms.length > 0">
                        <div class="col-md-4 mb-5" v-for="(room, index ) in rooms" :key="index">
                            <div class="room-box background-white">
                                <div class="room-name">{{room.name}}</div>
                                <div class="room-per">
                                    <star-rating v-bind:star-size="10" :show-rating="false" class="justify-content-center"></star-rating>
                                </div>
                                <img :src="room.picturesURL" alt="">
                                <div class="room-box-in">
                                    <h5 class="">{{room.name}}</h5>
                                    <p class="mt-3">{{room.roomDescription.split(" ").splice(0, 6).join(" ")}}</p>
                                    <p class="mt-3"  style="text-decoration:line-through">Price: ${{room.originalPrice}}</p>
                                    <p class="mt-3">Price: ${{room.price}}</p>
                                    <div class="room-icons mt-4 pt-4">
                                        <img src="assets/img/5.svg" alt="">
                                        <img src="assets/img/2.svg" alt="">
                                        <img src="assets/img/3.svg" alt="">
                                        <img src="assets/img/1.svg" alt="">
                                      <a href="javascript:void(0)" @click="update_single_room(room)">full info</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-12 text-center">
                            <h4 class="text-muted">Empty rooms history </h4>
                            <p>You have not uploaded any rooms</p>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import StarRating from 'vue-star-rating'
import {mapGetters} from "vuex";

export default {
    name: 'Rooms',
    components: {
        Navbar,
        StarRating,
        Footer
    },
    data(){
        return{
        }
    },
  computed:{
    ...mapGetters({
      isLoading: 'room/room_is_loading',
      rooms: 'room/get_my_rooms'
    })
  },
  methods:{
    async update_single_room(room) {
      await this.$store.commit('room/set_single_room', room)
      await this.$router.push({name: 'roomsgallery'})
    },

  },
    mounted(){
this.$store.dispatch('room/fetch_my_rooms')

    }
}
</script>

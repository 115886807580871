<template>
    <div>
        <Navbar />
        <div class="section big-55-height over-hide z-bigger" style="height:250px">

            <div id="poster_background-about"></div>
            <div id="video-wrap" class="parallax-top">
                <video id="video_background" preload="auto" autoplay loop="loop" muted="muted" poster="assets/img/image3.jpeg">
                    <source src="assets/video/videoE (2).mp4" type="video/mp4">
                </video>
            </div>
            <div class="dark-over-pages"></div>

            <div class="hero-center-section pages">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 parallax-fade-top">
                            <div class="hero-text">About Us</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="project-details-section ptb-100 mt-5">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-12 col-lg-6 mb-4">
                        <div class="img-wrap mb-md-4 mb-lg-0">
                            <img src="assets/img/about_us.69f36d47.jpg" alt="project" class="img-fluid rounded shadow-sm">
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="project-details-sidebar">
                            <ul class="project-info-list">
                                <li class="d-flex align-items-center mb-3 p-4 rounded shadow-sm border">
                                    <span class="ti-user icon-sm color-secondary d-block mr-3"></span>
                                    <div class="d-block">
                                        <h5 class="mb-0">Company Name</h5>
                                        <p>Have My Room</p>
                                    </div>
                                </li>
                                <li class="d-flex align-items-center mb-3 p-4 rounded shadow-sm border">
                                    <span class="ti-time icon-sm color-secondary d-block mr-3"></span>
                                    <div class="d-block">
                                        <h5 class="mb-0">Work Hours</h5>
                                        <p data-v-24ef6862="">24 / 7</p>
                                    </div>
                                </li>
                                <li class="d-flex align-items-center mb-3 p-4 rounded shadow-sm border">
                                    <span class="ti-email icon-sm color-secondary d-block mr-3"></span>
                                    <div class="d-block">
                                        <h5 class="mb-0">Email</h5>
                                        <a href="mailto:contact@havemyroom.com">contact@havemyroom.com</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="project-details-sidebar">
                            <ul class="project-info-list">
                                <li class="d-flex align-items-center mb-3 p-4 rounded shadow-sm border">
                                    <span class="ti-link icon-sm color-secondary d-block mr-3"></span>
                                    <div class="d-block">
                                        <h5 class="mb-0">Company Address</h5>
                                        <a href="javascript:void(0)">2A Breffni Court Watercourse road Cork Ireland</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-12">
                        <div class="project-details-content">
                            <h5>We care about you</h5>
                            <p> Have My Room is a responsive application strategically designed to offer innovative and accessible solutions to travelers across the world. <br>
                            <br>We built Have My Room simply because in 2019 booking your hotel room should be stress free and fun. We also believe that we need to be a message away from our client whether they are already on vacation or simply need more information we simply a WhatsApp away. It is simple, we partner with top rated hotels, they give us their unsold rooms and we show you the best deals. </p>
                            <p> At Have My Room, we also enable a user who cannot travel any longer to advertise their hotel rooms on our platform as well as a user who has to check out earlier than expected for personal reason but in both cases have a non-refundable rate, as a result they will get part of their money back and someone else will be thrilled with a great hotel deal </p>
                            <h5>Innovative Technology</h5>
                            <p>We take pride in the sophistication of innovative technologies at our disposal. Our processes are fast, efficient, and effective. All these we do to give you the best customer experience.</p>
                            <p>Still waiting? Download Have My Room app today and join thousands of hotel hunters enjoying the unlimited benefits we offer! We are available on both App Store for iPhone Users and Play Store for Android lovers</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <Footer/>
    </div>
</template>

<script>
import Footer from "./Footer";
import Navbar from "./Navbar";
export default {
  name: 'About',
  components: {
    Footer,
    Navbar
  }
}
</script>
<style scoped>
.icon-sm {
    font-size: 25px;
    line-height: 25px;
}
.color-secondary {
    color: #A67D00;
}
a{
      color: #A67D00;
}
</style>

<template>
  <div>
    <Navbar/>

    <div class="section big-55-height over-hide z-bigger" style="height:250px">
      <div class="parallax parallax-top" style="background-image: url('../assets/img/image7.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">FAQS</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="gray-light-bg pt-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>How do we contact you? </h5>
              <p> We are available 24/7 using WhatsApp or email. </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>What payment methods are available and how do I choose one?</h5>
              <p> You can get pay by Bank transfer or PayPal </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>How does Havemyroom secure my payment?</h5>
              <p> Havemyroom uses the most advanced processing system run by Stripe.com to ensure your payment is
                completely secure. We never store your credit card information and only accept 100% verified
                payments. </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>How does Havemyroom verify the reservation?</h5>
              <p> Havemyroom advanced technology identifies and either accepts or rejects the validity of every
                reservation listed for sale on our marketplace. Thanks to this technology, we can state with total
                confidence that each and every room that you see on our platform is 100% authentic, valid and bookable.
                Still not sure? Contact the hotel directly and we are always available through WhatsApp </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>What types of reservations does Havemyroom offer?</h5>
              <p> We offer three types of reservation: </p>
              <ol class="pl-4" style="list-style-type: lower-alpha;">
                <li> Hotel rooms provided by a traveller who cannot make it any longer</li>
                <li> Hotel rooms provided by our Hotel Partners</li>
                <li> Hotel rooms provided by a traveller who had to check out early</li>
              </ol>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>Is my credit card information secure?</h5>
              <p> You bet. We are PCI compliant, every credit card payment on Havemyroom is processed through a 3rd
                party(www.stripe.com), fully encrypted server. In other words your information is safe with us. </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>I have sold my reservation, when do I get paid?</h5>
              <p> You are anxious to get paid and we don't blame you. We want you to get your money as soon as possible.
                You will get pay 7 days after your reservation’s check-out date. The transfer time can vary depending on
                the payment method selected and depending on your bank. </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>Why do I get paid 7 days after check-out?</h5>
              <p> We are dedicated to protecting our customers and keeping the Havemyroom marketplace secure. In order
                to avoid fraud and ensure that everyone can use the platform with complete confidence, we hold the
                payment until 7 days after the check-out date. This allows us to confirm that the new guest was able to
                check-in and check-out without any problems. </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>The payment I received for my reservation doesn’t seem right?</h5>
              <p> Remember that Havemyroom charges a 15% service fee for promoting and hosting your hotel room listing.
                Each payment method also incurs its own fees, and your bank may charge additional fees. If your payment
                still does not look right, contact us and we’ll sort it out. </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>What is a Hot deal?</h5>
              <p> You will not find a cheaper deal somewhere else. A client who cannot complete his/her booking on the
                property may choose to list the remaining days with us and the price will always be much cheaper than
                the market value </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="single-faq mt-4">
              <h5>How can we pay?</h5>
              <p> We accept Visa, Mastercard and PayPal. Sometimes you may be able to pay directly at the Hotel. </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer/>
  </div>
</template>

<script>
import Navbar from "./Navbar";
import Footer from "./Footer";

export default {
  name: 'Faq',
  components: {
    Navbar,
    Footer
  }
}
</script>
